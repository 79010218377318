<template>
	<div class="q-pa-md">
		<div class="row">
			<div class="q-pa-md">
				<div class="row">
					<div class="row justify q-banner--top-padding q-pa-md">
						<q-select filled v-model="searchKey" use-input input-debounce="0" label="Search Employee"
				        :options="employeeList" @filter="filterFn" style="width: 550px" behavior="dialog" class="caps"
				      	>
				        <template v-slot:no-option>
				          <q-item>
				            <q-item-section class="text-grey">
				              No results
				            </q-item-section>
				          </q-item>
				        </template>
				      </q-select>
					</div>
	                <div class="row justify q-banner--top-padding q-pa-md">
						<q-select v-model="year" :options="yearOptions"  filled label="Select Year" class="caps" style="width:200px"/>
					</div>
	                <div class="row justify q-banner--top-padding q-pa-md">
						 <q-btn outline style="color: red;" label="Show Details" @click="showDetails" />
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
				<div class="q-pa-md">
				    <q-markup-table separator="cell">
				      <thead>
						  <tr>
  				          	<th class="text-center" colspan="3">SSS</th>
  				          	<th class="text-center" colspan="2">MANDATORY PROVIDENT SSS</th>
  				          	<th class="text-center">SSS ER TOTAL</th>
							<th class="text-center">SSS EE TOTAL</th>
							<th class="text-center" colspan="2">PHILHEALTH</th>
							<th class="text-center" colspan="2">HDMF</th>
							<th class="text-center" colspan="2">TOTAL MANDATORY DED</th>
							<th class="text-center" rowspan="2">WHT</th>
  				        </tr>
				        <tr>
				          	<th class="text-center">ER</th>
				          	<th class="text-center">EE</th>
				          	<th class="text-center">EC</th>

							<th class="text-center">ER</th>
				          	<th class="text-center">EE</th>

							<th class="text-center">ER</th>
				          	<th class="text-center">EE</th>

							<th class="text-center">ER</th>
							<th class="text-center">EE</th>

							<th class="text-center">ER</th>
							<th class="text-center">EE</th>

							<th class="text-center">ER</th>
							<th class="text-center">EE</th>
				        </tr>
				      </thead>
				      <tbody>
						<tr>
				          	<td class="text-center"></td>
							<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
						  	<td class="text-center"></td>
				        </tr>
				      </tbody>
				    </q-markup-table>
				  </div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';


export default {

	data () {
		return {
			searchKey:'',
			employeeList:[],

			year:'',
            yearOptions:[],

			monthlyGross:[],
			sssData:[],
			philData:[],
			pagiData:[],

			yearlyTotal:0,
			yearlySSSTotal:0,
			yearlyPhilTotal:0,
			yearlyPagiTotal:0,

		}
	},

	created(){
		this.getListOfEmployees()
		this.yearsList()

	},

	methods: {

		filterFn (val, update) {
	     	if (val === '') {
		        update(() => {
		          this.employeeList = this.employeeOptions
		        })
	        	return
	      	}
	      update(() => {
	        	const needle = val.toLowerCase()
	        	this.employeeList = this.employeeOptions.filter(emp => emp.toLowerCase().indexOf(needle) > -1)
	      })
	  	},

		showDetails:function(){
            this.$q.loading.show({
                spinnerColor: 'yellow',
                spinnerSize: 140,
                backgroundColor: 'purple',
                message: 'Generating in process...',
                messageColor: 'black'
            })
            var employeeID = this.searchKey.substr(0, this.searchKey.indexOf('|'));
            let formData = new FormData();
            formData.append("empID", employeeID);
            formData.append("year", this.year);
            axios.create({
                baseURL: this.apiUrl,
                headers: { 'Authorization': `Bearer ${this.token}` }
              }).post(`/backend/alphalist`, formData)
              .then((res) => {
                    this.monthlyGross = res.data.data.monthlyGross
					this.sssData = res.data.data.sssTotal
					this.philData = res.data.data.philTotal
					this.pagiData = res.data.data.pagiTotal

					this.yearlyTotal = res.data.data.yearlyTotal
					this.yearlySSSTotal = res.data.data.yearSSSTotal
					this.yearlyPhilTotal = res.data.data.yearPhilTotal
					this.yearlyPagiTotal = res.data.data.yearPagiTotal

                    this.timer = setTimeout(() => {
                        this.$q.loading.hide()
                        this.timer = void 0
                    }, 1000)
              });
        },

		getListOfEmployees: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/employee/list`)
			.then((res) => {
				this.employeeOptions = res.data.data;
			})

		},
		yearsList : function(){
            axios.create({
                baseURL : this.apiUrl,
                headers : {'Authorization' : `Bearer ${this.token}`}
            }).post(`/timekeeping/years/list`)
            .then((res) => {
                this.yearOptions = res.data.data
            });
        },
	}
}
</script>

<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-btn color="primary" label="Refresh" @click="getTKSummaryRequestHistory"/>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
			<q-table :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="TK Summary" :data="generatedData" :columns="columns"
			:pagination.sync="pagination" row-key="id" separator="cell"
			no-data-label="I didn't find anything for you!" :loading="loading" :filter="filter" binary-state-sort>
			<template v-slot:header="props">
				<q-tr :props="props">
					<q-th v-for="col in props.cols" :key="col.name" :props="props">
						{{ col.label }}
						<q-tooltip anchor="top middle" content-class="bg-red" :offset="[10, 10]">
							{{ col.desc }}
						</q-tooltip>
					</q-th>
				</q-tr>
			</template>
			<template v-slot:body="props">
				<q-tr :props="props" style="cursor: pointer;"  @click="selectRow(props.row)">
					<q-td key="empID" :props="props">{{ props.row.empID }}</q-td>
					<q-td key="request_by" :props="props">{{ props.row.request_by }}</q-td>
					<q-td key="request_date" :props="props">{{ props.row.request_date }}</q-td>
					<q-td key="status" :props="props">{{ props.row.status }}</q-td>
					<q-td key="action_by" :props="props">{{ props.row.action_by }}</q-td>
					<q-td key="approved_date" :props="props">{{ props.row.approved_date }}</q-td>
					<q-td key="alter_remarks" :props="props">{{ props.row.alter_remarks }}</q-td>
					<q-td key="decline_remarks" :props="props">{{ props.row.decline_remarks }}</q-td>
				</q-tr>
			</template>
			<template v-slot:no-data="">
	          <div class="full-width row flex-center text-red q-gutter-sm">
	            <i class="fas fa-exclamation-triangle"></i>
	            <span>The filter didn't uncover any results!</span>
	          </div>
	        </template>
			<template v-slot:loading>
	            <q-inner-loading showing>
	                <q-spinner-hourglass  size="50px" color="cyan" />
	            </q-inner-loading>
	        </template>
			<template v-slot:top>
			  <q-input dense v-model="filter" placeholder="Search Employee">
				<i class="fas fa-search" slot="append"></i>
			  </q-input>
			  <q-space />
			  <q-btn outline color="primary" label="Download" no-caps @click="exportTable" />
			</template>
		</q-table>
	</div>


	<PendingDialog></PendingDialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
import PendingDialog from './TKPendingRequestDialog';
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	components: {
      "PendingDialog": PendingDialog,
    },
	data () {
		return {
			filter: '',
	        loading: false,
			id:0,
			declineremarks:'',

	        pagination: {
	            page: 1,
	            rowsPerPage: 15,
	            rowsNumber: 10
	        },

			searchKey:'',
			yearfilter:new Date().getFullYear(),
	        yearOptions: [],
			separator:'cell',

			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,

			generatedData:[],

			columns: [
				{ name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true, desc: 'Employee ID' },
		        { name: 'request_by', align: 'center', label: 'Request By', field: 'request_by', sortable: true, desc: 'Request By' },
				{ name: 'request_date', align: 'center', label: 'Request Date', field: 'request_date', sortable: true, desc: 'Request Date' },
				{ name: 'status', align: 'center', label: 'Status', field: 'status', sortable: true, desc: 'Status' },
				{ name: 'action_by', align: 'center', label: 'Action By', field: 'action_by', sortable: true, desc: 'Action By' },
				{ name: 'approved_date', align: 'center', label: 'Approved Date', field: 'approved_date', sortable: true, desc: 'Approved Date' },
				{ name: 'alter_remarks', align: 'center', label: 'Reason', field: 'alter_remarks', sortable: true, desc: 'Reason' },
				{ name: 'decline_remarks', align: 'center', label: 'Decline Reason', field: 'decline_remarks', sortable: true, desc: 'Decline Reason' },
			],
		}
	},

	created(){
		this.getTKSummaryRequestHistory()
	},


	methods: {


		getTKSummaryRequestHistory: function(){
	      this.spinner = true;
	      let formData = new FormData();
	      // formData.append("year", this.yearfilter);
	      // formData.append("month", this.month);
	      // formData.append("cutoff", this.cutoff);
	      axios.create({
	          baseURL: this.apiUrl,
	          headers: { 'Authorization': `Bearer ${this.token}` }
	        }).post(`/timekeeping/request/history`, formData)
	        .then((res) => {
	          this.generatedData = res.data.data

			  console.log(res.data)
	        });
	    },


		selectRow:function(obj){

		},

		yearsList : function(){
	        axios.create({
	            baseURL : this.apiUrl,
	            headers : {'Authorization' : `Bearer ${this.token}`}
	        }).post(`/timekeeping/years/list`)
	        .then((res) => {
	            this.yearOptions = res.data.data
	        });
	    },

		exportTable:function(){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
			'TKSummary.csv',
			content,
			'text/csv'
			)

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
<style lang="sass" scoped>
	// .my-sticky-column-table
	// 	thead tr:nth-child(2) th:nth-child(2)
	// 		background-color: #fff
	// 	td:nth-child(2)
	// 		background-color: #c1f4cd
	// 	th:nth-child(2)
	// 	td:nth-child(2)
	// 		position: sticky
	// 		left: 0
	// 		z-index: 1
</style>

<template>
  <div class="login-container">
    <div class="login-box">
      <img class="logo q-mb-lg" src="../assets/sgcc-black.png" alt="">
      <h6 class="q-ma-none q-mb-lg text-grey-9"><span class="text-red-6"> Human Resources</span> Information System</h6>
      <q-form ref="vForm" @submit="onSubmit" class="col-gutter-y-sm">
        <p class="text-red" v-if="isInvalid">*Invalid Credentials</p>
        <q-input filled :disable="isSubmitted" v-model="login" label="Username or Email" lazy-rules :rules="[formRules.required]" >
          <template v-slot:prepend>
            <q-icon name="fa fa-user" />
          </template>
        </q-input>
        <q-input filled :disable="isSubmitted" type="password" v-model="password" label="Password" lazy-rules :rules="[formRules.required]">
          <template v-slot:prepend>
            <q-icon name="fa fa-unlock-alt" />
          </template>
        </q-input>
        <q-btn :loading="isSubmitted" type="submit" color="red" text-color="white" style="height: 50px; width: 100%; margin-top: 20px;" label="Submit" />
      </q-form>
    </div>
    <a href="#" class="forgot-password" @click="prompt = true" >Forgot Password</a>
    <p class="get-app"><b>Get the app.</b></p>
    <div class="q-pa-md q-gutter-sm play-store-box">
      <a href="#" @click="apple = true">
        <img src="../assets/appstore.png" alt="Download on the AppStore" height="40" >
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.sgcc.sgcc_app" target="_blank">
         <img src="../assets/play_store.png" alt="Get on Google Play" height="40" >
      </a>
    </div>

    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Enter your employee ID</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="employeeid" autofocus label="Employee ID"/>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="Submit" @click="forgotPassword"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="apple" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">How to download iOS app</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
            <q-list padding dense class="rounded-borders">
               <q-item clickable v-ripple>
                 <q-item-section class="text-red">Request a redemption code for IT personnel.</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>1. Open your App Store application.</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>2. Click your Account.</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>3. Click "Redeem Gift Card or Code".</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>4. Click "Enter Code Manually".</q-item-section>
               </q-item>
               <q-item clickable v-ripple>
                 <q-item-section>5. Enter CODE and click "Redeem" button.</q-item-section>
               </q-item>
            </q-list>
        </q-card-section>
        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Close" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>

</template>
<script>
import axios from 'axios';
import vueCookies from 'vue-cookies';
export default {
  name: "Login",
  data: () => ({
    login: null,
    password: null,
    isSubmitted: false,
    isInvalid: false,

    prompt: false,
    apple: false,
    employeeid:''
  }),
  methods: {

    onSubmit: function() {
      let formData = new FormData();
      formData.append("login", this.login.trim());
      formData.append("password", this.password.trim());
      this.isSubmitted = true;
      this.isInvalid = false;
      axios.post(this.apiUrl + "/login", formData)
        .then((res) => {
          if (res.data.status) {
            vueCookies.set(this.cookieKey, {
              'user': res.data.data,
              'token': res.data.token
            });
            setTimeout(() => {
              location.reload();
            }, 500)
          } else {
            this.isInvalid = true;
            this.isSubmitted = false;
          }
        });
    },

    forgotPassword: function() {
        const dismiss = this.$q.notify({
              spinner: true,
              message: 'Please wait...',
              timeout: 0,
              color: 'green',
              position:'center',
        })
        if(this.employeeid.trim() != ''){
            let formData = new FormData();
            formData.append("empID", this.employeeid.trim());

            axios.post(this.apiUrl + "/mobile/forgot/password", formData).then((res) => {
              if (res.data.status) {
                  dismiss(),
                  this.prompt = false,
                  this.$q.notify({
                    message: res.data.message,
                    color: 'green',
                    position:'center',
                    timeout: 3000,
                  })

              } else {
                  dismiss(),
                  this.$q.notify({
                      message: res.data.message,
                      color: 'red',
                      position:'center',
                       timeout: 1000
                  })
              }
            });

        }else{
            dismiss(),
            this.$q.notify({
                message: 'Employee ID is required!',
                color: 'red',
                position:'bottom',
                 timeout: 1000
            })

        }
    }
  }
};
</script>
<style scoped>

/* CSS that should be displayed if width is equal to or greater than 850px goes here */
.login-container {
  width: 100vw;
  height: 100vh;
  background: url('../assets/office-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.login-box {
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  top: 40%;
  left: 50%;
  width: auto;
  min-height: 200px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 20px #333;
  text-align: center;
}
.login-box .title {}
.login-box .logo {
  width: 400px;
  position: relative;
  left: 0px;
}

.forgot-password {
  position: absolute;
  top:70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.get-app {
  position: absolute;
  top:75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.play-store-box {
  position: absolute;
  top:80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


/* for iphone size*/
@media screen and (max-width: 414px) and (max-height: 896px){

  .login-box {
    top: 40%;
    left: 50%;
    min-height: 50px;
  }

  .login-box .title {}
  .login-box .logo {
    width: 300px;
  }

  .forgot-password {
    top:75%;
    left: 50%;
  }

  .get-app {
    top:80%;
    left: 50%;
  }

  .play-store-box {
    top:85%;
    left: 50%;
  }

}

@media screen and (max-width: 414px) and (max-height: 950px){

  .login-box {
    top: 40%;
    left: 50%;
    min-height: 50px;
  }

  .login-box .title {}
  .login-box .logo {
    width: 300px;
  }

  .forgot-password {
    top:75%;
    left: 50%;
  }

  .get-app {
    top:80%;
    left: 50%;
  }

  .play-store-box {
    top:85%;
    left: 50%;
  }

}

/* for iphone size || iphone se/5*/
@media screen and (max-width: 375px) and (max-height: 667px){

  .login-box {
    top: 40%;
    left: 50%;
    min-height: 50px;
  }

  .login-box .title {}
  .login-box .logo {
    width: 280px;
    margin-bottom: 0px!important;
  }

  .forgot-password {
    top:80%;
    left: 50%;
  }

  .get-app {
    top:85%;
    left: 50%;
  }

  .play-store-box {
    top:90%;
    left: 50%;
  }

}

/* for samsung size*/
@media screen and (max-width: 360px) and (max-height: 740px){

  .login-container {
    width: 110vw !important;
    height: 110vh !important;
  }

  .login-box {
    top: 40%;
    left: 50%;
    min-height: 50px;
  }

  .login-box .title {}
  .login-box .logo {
    width: 300px;
  }

  .forgot-password {
    top:75%;
    left: 50%;
  }

  .get-app {
    top:80%;
    left: 50%;
  }

  .play-store-box {
    top:85%;
    left: 50%;
  }

}

/* for samsung fold size*/
@media screen and (max-width: 280px) and (max-height: 653px){

  .login-container {
    width: 120vw !important;
    height: 120vh !important;
  }

  .login-box {
    top: 40%;
    left: 50%;
    min-height: 50px;
  }

  .login-box .title {}
  .login-box .logo {
    width: 250px;
  }

  .forgot-password {
    top:75%;
    left: 50%;
  }

  .get-app {
    top:80%;
    left: 50%;
  }

  .play-store-box {
    top:85%;
    left: 50%;
  }

}

/* for surface duo size*/
@media screen and (min-width: 540px) and (max-height: 720px){

  .login-container {
    width: 100vw !important;
    height: 100vh !important;
  }

  .login-box {
    top: 40%;
    left: 50%;
    min-height: 100px;
  }

  .login-box .title {}
  .login-box .logo {
    width: 450px;
  }

  .forgot-password {
    top:80%;
    left: 50%;
  }

  .get-app {
    top:85%;
    left: 50%;
  }

  .play-store-box {
    top:90%;
    left: 50%;
  }

}

/* for tablet size*/
@media screen and (min-width: 912px;max-width: 1000px;) and (max-height: 1368px){

  .login-container {
    width: 110vw !important;
    height: 110vh !important;
  }

  .login-box {
    top: 40%;
    left: 50%;
    min-height: 100px;
  }

  .login-box .title {}
  .login-box .logo {
    width: 600px;
  }

  .forgot-password {
    top:60%;
    left: 50%;
  }

  .get-app {
    top:65%;
    left: 50%;
  }

  .play-store-box {
    top:70%;
    left: 50%;
  }

}


</style>

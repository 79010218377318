<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
					<q-btn color="primary" label="Filter" @click="filterData"/>
					<q-btn color="primary" label="Create" @click="createCutOff"/>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
			<q-table
			:dense="$q.screen.lt.md"
			:grid="$q.screen.xs"
			title="Periodic Table"
			:data="generatedData"
			:columns="columns"
			:pagination="initialPagination"
			row-key="id">
			<template v-slot:body="props">
				<q-tr slot="body"  :props="props" @click="selectedRow(props)" style="cursor: pointer;">
					<q-td key="months" :props="props">{{ props.row.months }}</q-td>
					<q-td key="cut_off" :props="props">{{ props.row.cut_off }}</q-td>
					<q-td key="payroll_cutoff" :props="props">{{ props.row.payroll_cutoff }}</q-td>
					<q-td key="payroll_period" :props="props">{{ props.row.payroll_period }}</q-td>
					<q-td key="paydate" :props="props">{{ props.row.paydate }}</q-td>
				</q-tr>
			</template>
			<template v-slot:no-data="">
				<div class="full-width row flex-center text-red q-gutter-sm">
					<i class="fas fa-exclamation-triangle"></i>
					<span>
						No Result Found!
					</span>
				</div>
			</template>
			<template v-slot:top-right>
				<q-btn outline color="primary" label="Export" no-caps @click="exportTable"/>
			</template>
		</q-table>
	</div>

	<q-dialog v-model="spinner" persistent >
		<q-card class="my-card transparent no-shadow">
			<q-card-section >
				<q-spinner-gears color="cyan"  size="5.5em"/>
			</q-card-section>
		</q-card>
	</q-dialog>

	<q-dialog v-model="addDialog" persistent transition-show="scale">
      	<q-card style="width: 400px">
        	<q-card-section>
          		<div class="text-h6">Create Cut-Off</div>
        	</q-card-section>
        	<q-separator />
        	<q-card-section>
				<div class="q-pa-md" >
					<q-form @submit="onSubmit" class="q-gutter-md">
					<q-input filled v-model="year" label="Year"/>
					<q-select filled v-model="months" :options="monthOptions" transition-show="flip-up"  label="Month" emit-value map-options option-value="id" option-label="name"  />
					<q-select filled v-model="cut_off" :options="cutOffOptions" transition-show="flip-up"  label="Cut Off" emit-value map-options option-value="id" option-label="name"  />

					<q-input filled outlined v-model="payroll_cutoff_from" mask="date" :rules="['date']" label="Payroll From">
						<template v-slot:append>
							<i class="far fa-calendar-alt cursor-pointer" >
								<q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
									<q-date v-model="payroll_cutoff_from" @input="() => $refs.dhDateProxy.hide()" />
								</q-popup-proxy>
							</i>
						</template>
					</q-input>
					<q-input filled outlined v-model="payroll_cutoff_to" mask="date" :rules="['date']" label="Payroll To">
						<template v-slot:append>
							<i class="far fa-calendar-alt cursor-pointer" >
								<q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
									<q-date v-model="payroll_cutoff_to" @input="() => $refs.dhDateProxy.hide()" />
								</q-popup-proxy>
							</i>
						</template>
					</q-input>

					<q-input filled v-model="payroll_period" label="Payroll Period" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
					<q-input filled outlined v-model="pay_date" mask="date" :rules="['date']" label="Pay Date">
						<template v-slot:append>
							<i class="far fa-calendar-alt cursor-pointer" >
								<q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
									<q-date v-model="pay_date" @input="() => $refs.dhDateProxy.hide()" />
								</q-popup-proxy>
							</i>
						</template>
					</q-input>
				  	<div>
						<q-btn label="Submit" type="submit" flat color="primary"/>
						<q-btn label="Close" type="reset" color="primary" flat class="q-ml-sm" v-close-popup/>
				  	</div>
					</q-form>
				</div>
			</q-card-section>
        	<q-separator />
      	</q-card>
    </q-dialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	data () {
		return {
			tempID:0,
			initialPagination: {rowsPerPage: 30},
			addDialog: false,
			yearfilter:new Date().getFullYear(),
			year:new Date().getFullYear(),
			months:'',
			cut_off:'',
			payroll_cutoff_from:'',
			payroll_cutoff_to:'',
			payroll_period:'',
			pay_date:'',
			yearOptions: [],
			monthOptions:[{id:1,name:'January'},{id:2,name:'February'},{id:3,name:'March'},{id:4,name:'April'},{id:5,name:'May'},{id:6,name:'June'},{id:7,name:'July'},{id:8,name:'August'},{id:9,name:'September'},{id:10,name:'October'},{id:11,name:'November'},{id:12,name:'December'},],
			cutOffOptions: [{id:1,name:'First Cut Off'},{id:2,name:'Second Cut Off'}],

			separator:'cell',
			spinner:false,
			generatedData:[],
			data: [],
			columns: [
			//{ name: 'years', align: 'center', label: 'Years', field: 'years', sortable: true,  desc:'Years' },
			{ name: 'months', align: 'center', label: 'Months', field: 'months', sortable: true,  desc:'Months' },
			{ name: 'cut_off', align: 'center', label: 'Cut Off', field: 'cut_off', sortable: true,  desc:'Cut Off' },
			{ name: 'payroll_cutoff', align: 'center', label: 'Payroll Cut-Off  (Coverage)', field: 'payroll_cutoff' , sortable: true,desc:'Payroll Cut-Off'},
			{ name: 'payroll_period', align: 'center', label: 'Payroll Period', field: 'payroll_period' , sortable: true,desc:'Payroll Period'},
			{ name: 'paydate', align: 'center', label: 'Pay Date', field: 'paydate' , sortable: true,desc:'Pay Date'},
			],

		}
	},

	created(){
		this.filterData()
		this.yearsList()
	},

	methods: {
		onSubmit () {
			let formData = new FormData();
			formData.append("tempID", this.tempID);
			formData.append("years", this.year);
			formData.append("months", this.months);
			formData.append("cut_off", this.cut_off);
			formData.append("payroll_cutoff_from", this.payroll_cutoff_from);
			formData.append("payroll_cutoff_to", this.payroll_cutoff_to);
			formData.append("payroll_period", this.payroll_period);
			formData.append("pay_date", this.pay_date);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/timekeeping/periodic',formData)
			.then((res) => {
				this.tempID = 0
				this.onReset()
				this.filterData()
				this.$q.notify({
		          color: 'green-9',
		          textColor: 'white',
		          message: 'Submitted'
		        })
				this.addDialog = false
			});

    	},

		onReset () {
			this.months = ''
  		  	this.cut_off = ''
  		  	this.payroll_cutoff_from = ''
  		  	this.payroll_cutoff_to = ''
  		  	this.payroll_period = ''
  		  	this.pay_date = ''
			this.tempID = 0
	    },

		createCutOff: function(){
			this.addDialog = true
		},

		selectedRow:function(obj){

			this.months = obj.row.months,
			this.cut_off = obj.row.cut_off,
			this.payroll_cutoff_from = obj.row.payroll_cutoff_from,
			this.payroll_cutoff_to = obj.row.payroll_cutoff_to,
			this.payroll_period = obj.row.payroll_period,
			this.pay_date = obj.row.paydate,
			this.tempID = obj.row.id
			this.addDialog = true

		},

		filterData : function(){
			this.spinner = true;
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/periodic/all`,formData)
			.then((res) => {
				//console.log(res.data)
				this.generatedData = res.data.data
				this.spinner = false
			});
		},

		yearsList : function(){
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/years/list`)
			.then((res) => {
				this.yearOptions = res.data.data
			});
		},

		exportTable:function(){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
			'PeriodicTable.csv',
			content,
			'text/csv'
			)

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
<style lang="sass" scoped>
	.my-sticky-column-table
		thead tr:nth-child(2) th:nth-child(2)
			background-color: #fff
		td:nth-child(2)
			background-color: #c1f4cd
		th:nth-child(2)
		td:nth-child(2)
			position: sticky
			left: 0
			z-index: 1
</style>

<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width text-h4" style="height: 40px;">
          Final Interview List
          <q-chip square class="q-ml-sm text-bold" size="md" color="light-blue" text-color="white">
            <q-avatar font-size="14px" color="blue-grey-8" text-color="white" style="width:1.7em">{{ applicationCounts }}</q-avatar>
            Applications
          </q-chip>
        </div>
        <div class="row q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
          <q-btn outline label="PASSED" @click="multipleForFinalInterview('PASSED')" color="green"/>
          <q-btn outline label="FAILED" @click="multipleForFinalInterview('FAILED')" color="red"/>
          <q-btn outline label="FOR EXAM" @click="multipleForExam" color="indigo-7"/>
        </div>
      </div>
      <div class="full-width row justify-center q-ma-md">
        <q-card bordered class="my-card col-12 q-pa-md">
          <q-card-section class="q-pa-none q-ma-none q-mt-sm">
            <div class="row">
              <div class="col-12">
                <!-- :visible-columns="visibleColumns" 01182023 -->
                <q-table :data="applicationsData" :columns="ResultColumns" :pagination.sync="Pagination" :filter="filter"
                row-key="id" dense :visible-columns="visibleColumns"
                selection="multiple" :selected.sync="forFinalInterviewSelected"
                virtual-scroll :virtual-scroll-item-size="48" style="max-height: 600px">
                <!-- END:: :visible-columns="visibleColumns" 01182023 -->
                  <template v-slot:top-left>
                    <div style="display:inline-flex;">
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateFrom" outlined class="q-mr-sm" hint="Date From"/>
                      <q-input type="date" dense debounce="400" color="primary" v-model="dateTo" outlined class="q-mr-sm" hint="Date To"/>
                      <q-select dense v-model="site" :options="sites" outlined class="q-mr-sm"  />
                      <q-btn class="q-mb-lg q-mr-sm" label="Search" color="primary" @click="searchDate"/>
                      <q-btn
                        class="q-mb-lg q-mr-sm"
                        color="primary"
                        icon-right="fas fa-download"
                        label="Export to csv"
                        no-caps
                        @click="exportTable"
                      />

                    </div>
                  </template>
                  <template v-slot:top-right>
                    <q-input borderless dense debounce="300" v-model="filter" placeholder="Search" outlined @change="searchData">
                      <template v-slot:append>
                        <q-icon name="fas fa-search" />
                      </template>
                    </q-input>
                  </template>
                  <q-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer">
                    <q-td>
                      <!-- <q-checkbox dense v-if="props.row.passfail == 'N/A' && props.row.exam_source != 'BUPLAS'" disable v-model="props.selected" /> -->
                      <q-checkbox dense v-model="props.selected" />
                    </q-td>
                    <q-td key="action" :props="props" style="text-align:center;">
                      <q-btn color="red" @click="interviewResult(props.row.id, props.row.applicant_id, 'Failed')"  rounded size="sm" text-color="white" label="Failed" />
                      <q-btn color="green" v-if="props.row.telco == false" @click="interviewResult(props.row.id, props.row.applicant_id, 'Passed')" rounded size="sm" text-color="white" label="Passed" />
                      <q-btn color="indigo" v-if="props.row.telco == true" @click="singleForExam(props.row.id, props.row.applicant_id)" rounded size="sm" text-color="white" label="For Exam" />
                    </q-td>
                    <!-- <q-td key="trainee_id" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.trainee_id }}</q-td> -->
                  <!-- 01182023 for sorting purposes-->
                    <q-td key="a_id" :props="props">{{ props.row.id }}</q-td>
                  <!-- END:: 01182023 -->
                   <q-td key="site" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.site }}</q-td>
                    <q-td key="applicant_id" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.applicant_id }}</q-td>
                    <q-td key="firstname" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.firstname }}</q-td>
                    <q-td key="middlename" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.middlename }}</q-td>
                    <q-td key="lastname" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.lastname }}</q-td>
                    <q-td key="mobile" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.mobile }}</q-td>
                    <q-td key="email" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.email }}</q-td>
                    <q-td key="presaddress" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.presaddress }}</q-td>
                    <q-td key="prescity" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.prescity }}</q-td>
                    <q-td key="civil_status" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.civil_status }}</q-td>
                    <q-td key="gender" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.gender }}</q-td>
                    <q-td key="date_of_birth" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.date_of_birth }}</q-td>
                    <q-td key="edattain" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.edattain }}</q-td>
                    <q-td key="course" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.course }}</q-td>
                    <q-td key="estrainee" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.estrainee }}</q-td>
                    <q-td key="account" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.account }}</q-td>
                    <q-td key="batch" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.batch }}</q-td>
                    <q-td key="date_hired" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.date_hired }}</q-td>
                    <q-td key="expected_pbdate" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.expected_pbdate }}</q-td>
                    <q-td key="result" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.result }}</q-td>

                    <q-td key="date_of_application" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.date_of_application }}</q-td>
                    <q-td key="position_applying_for" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.position_applying_for }}</q-td>
                    <q-td key="with_call_center_experience" :props="props" @click="viewDetails('Final Interview','',props.row.id)">{{ props.row.with_call_center_experience }}</q-td>
                    <q-td key="reprofile_count" :props="props " >{{ props.row.reprofile_count }}</q-td> <!-- 01182023 -->
                  </q-tr>
                  <template v-slot:no-data>
                    <div class="full-width row flex-center text-amber-10 q-gutter-sm">
                      <span>
                        No Applications Yet
                      </span>
                      <q-icon size="1.5em" name="fa fa-exclamation-triangle" />
                    </div>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="loadingModel" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            {{loadingText}}
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="forInterviewFailedDialog" persistent>
      <q-card>
        <q-card-section class="row q-pa-sm bg-red-8 text-white">
          <span class="q-mx-auto text-bold">Failed For Final Interview</span>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col-4 q-pr-sm">Date</div>
            <div class="col-4 q-px-sm">Account/Dept</div>
            <div class="col-4 q-pl-sm">Interviewer</div>
          </div>
          <div class="row">
            <div class="col-4 q-pr-sm">
              <q-input type="date" v-model="form_int.date" outlined color="indigo-6" dense></q-input>
            </div>
            <div class="col-4 q-px-sm">
              <q-input v-model="form_int.account_dept" outlined color="indigo-6" dense></q-input>
            </div>
             <div class="col-4 q-pl-sm">
              <q-input v-model="form_int.interviewer" outlined color="indigo-6" dense></q-input>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" v-close-popup dense/>
          <q-btn flat label="Failed" @click="interview('Failed')" class="bg-red-8 text-white" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="forInterviewPassedDialog" persistent>
      <q-card>
        <q-card-section class="row q-pa-sm bg-green-8 text-white">
          <span class="q-mx-auto text-bold">Passed For Final Interview</span>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col-6 q-pr-sm">Date</div>
            <div class="col-6 q-pl-sm">Account/Dept</div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">
              <q-input type="date" v-model="form_int.date" outlined color="indigo-6" dense></q-input>
            </div>
            <div class="col-6 q-pl-sm">
              <q-input v-model="form_int.account_dept" outlined color="indigo-6" dense></q-input>
            </div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">Interviewer</div>
            <div class="col-6 q-pl-sm">Orientation Date</div>
          </div>
          <div class="row">
            <div class="col-6 q-pr-sm">
              <q-input v-model="form_int.interviewer" outlined color="indigo-6" dense></q-input>
            </div>
            <div class="col-6 q-pl-sm">
              <q-input v-model="form_int.orientation_date" type="date" outlined color="indigo-6" dense></q-input>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="indigo-6" v-close-popup dense/>
          <q-btn flat label="Passed" @click="interview('Passed')" class="bg-green-8 text-white" v-close-popup dense/>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loadingAction" persistent>
      <q-card class="my-card transparent no-shadow">
        <q-card-section class="q-pa-lg">
          <div class="q-ma-md q-mb-xl">
            <div class="items-center">
              <q-spinner-cube color="white" size="5.5em" />
            </div>
          </div>
          <div class="absolute-bottom text-subtitle1 text-center q-mb-sm" style="color: white">
            Processing Application
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog v-model="viewDetailsDialog" transition-show="scale" transition-hide="scale">
      <div class="bg-white" style="height: auto; width: 1200px; max-width: 1200px;">
        <div class="row full-width q-pa-sm bg-indigo-6 text-center text-white sticky">
          <div class="text-h5">{{ vAD.name }}</div>
          <div>( {{ vAD.trackingID }} / {{ vAD.dateApplied }} )</div>
        </div>
        <div class="col-12 q-ma-sm">
          <q-card class="my-card transparent no-shadow">
            <q-card-section class="q-mt-sm q-pa-md">
              <div class="text-bold text-head">Personal Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Present Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.presentAddress}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Permanent Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.permanentAddress}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Email Address" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.email}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Mobile Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.mobileNumber}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Telephone Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.telNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Gender" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.gender}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Birthday" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.birthday}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Civil Status" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.civilStatus}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="Religion" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.religion}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Application Information</div>
              <div class="row q-col-gutter-md">
                <div class="col-6">
                  <q-field label="Know Company Through" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.through}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-6">
                  <q-field label="Position Applying For" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.positionApplyingFor}}</div>
                    </template>
                  </q-field>
                </div>
              </div>

              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-3">
                  <q-field label="Onsite?" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.onsite}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="WFH?" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.wfh}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Mode of Transportation" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.trasportation}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Availability to join?" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.availabilityToJoin}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-3">
                  <q-field label="Pending Application" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.pendingApplication}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Account" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.account}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.position}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Recruiter" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.recruiter}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-4">
                  <q-field label="Exam Date" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.examDate}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Exam Result" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.examResult}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-3">
                  <q-field label="ISP" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.isp}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Speed" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.speed}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Previous Salary" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.prevSalary}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Expected Salary" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.expSalary}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-12">
                  <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.remarks}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Work Eperiences</div>
              <div class="row q-col-gutter-md">
                <div class="col">
                  <q-field label="With Call Center Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.callCenterExp}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Account Handled" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpAccount}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.callCenterExp == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.ccExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-md">
                <div class="col q-mt-sm q-pt-none">
                  <q-field label="With Other Work Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.otherWorkExperience}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Position" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpPosition}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Year(s) of Experience" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpDuration}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs">
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Company" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpCompany}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Nature of Work" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpBusNature}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col q-mt-sm q-pt-none" v-if="vAD.otherWorkExperience == 'Yes'">
                  <q-field label="Reason for Leaving" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.owExpReasonLeave}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Educational Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-2">
                  <q-field label="Highest Educational Attainment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.educAttainment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1">
                  <q-field label="Year Attended" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearAttended}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-5">
                  <q-field label="School / University / College" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.suc}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3" v-if="vAD.educAttainment != 'High School Graduate'">
                  <q-field label="Course" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.course}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-1" v-if="vAD.educAttainment == 'College Level'">
                  <q-field label="Year Level" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.yearLevel}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md" v-if="vAD.natureOfIllness1 != 'N/A'">
              <div class="text-bold text-head">Medical / Health Background</div>
              <div class="row q-col-gutter-md">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness1}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration1}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness2 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness2}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration2}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row q-col-gutter-md q-mt-xs" v-if="vAD.natureOfIllness3 != 'N/A'">
                <div class="col-8">
                  <q-field label="Nature of Illness" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.natureOfIllness3}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Duration" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.illnessDuration3}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Emergency Contact</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Contact Person" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactPerson}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Relationship" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactRelation}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-4">
                  <q-field label="Contact Number" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.contactNumber}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-mt-xs q-pa-md">
              <div class="text-bold text-head">Assessment</div>
              <div class="row q-col-gutter-md">
                <div class="col-4">
                  <q-field label="Remarks" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessment}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Assessed By" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessedBy}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-3">
                  <q-field label="Date of Assessment" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.assessmentDate}}</div>
                    </template>
                  </q-field>
                </div>
                <div class="col-2">
                  <q-field label="Last Process" label-color="indigo-7" stack-label color="indigo-7">
                    <template v-slot:control>
                      <div>{{vAD.lastProcess}}</div>
                    </template>
                  </q-field>
                </div>
              </div>
            </q-card-section>
            <q-card-actions class="row col-12 q-pb-md q-mt-none q-mt-md">
               <div class="col-9 q-pl-sm "> </div>
              <div class="col-3 q-pl-sm justify-end">
                <q-btn label="CLOSE" class="full-width bg-indigo-6 text-white btn-height" v-close-popup />
              </div>
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </q-dialog>
    <q-dialog v-model="actionDialog" persistent>
      <div class="bg-white" style="height: auto; width: 500px; max-width: 500px;">
        <q-card>
          <q-card-section class="row q-pa-sm bg-amber-8 text-white">
            <q-icon size="1.5em" name="fa fa-exclamation-circle" />
            <span class="q-pl-sm">Application Assessment : [ {{ form.assessorAction }} ]</span>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm" v-if="form.assessorAction == 'Failed'">
            <div class="q-gutter-sm">
              <q-radio v-model="form.failedAssessment" val="Failed" label="Failed" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Reconsideration" label="For Reconsideration" color="amber-8" />
              <q-radio v-model="form.failedAssessment" val="For Pooling" label="For Pooling" color="amber-8" />
            </div>
          </q-card-section>
          <q-card-section class="q-pa-md q-mt-sm">
            <span class="small-text">Assessment:</span>
            <q-input v-model="form.assessorRemarks" dense color="amber-8">
              <template v-slot:append>
                <q-icon name="fa fa-times" @click="form.assessorRemarks = null" class="cursor-pointer" />
              </template>
            </q-input>
          </q-card-section>
          <q-card-actions class="q-pa-md" align="right">
            <q-btn flat label="Cancel" @click="form.assessorRemarks = null,form.failedAssessment = null" color="amber-8" v-close-popup />
            <q-btn flat label="Confirm" @click="processAssessment" class="bg-amber-8 text-white" />
          </q-card-actions>
        </q-card>
      </div>
    </q-dialog>
    <q-dialog v-model="notSelectedDialog" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="fas fa-exclamation" color="red" text-color="white" />
          <span class="q-ml-sm"> {{ error_not_selected }}
          </span>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Okay" color="green" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="forExamDialog" persistent>
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="row q-pa-sm bg-indigo-6 text-white">
          <q-icon size="1.5em" name="fa fa-exclamation-circle" />
          <span class="q-pl-sm">Schedule this applicant/s for Exam?</span>
        </q-card-section>
        <q-card-section>
          <div class="row modal-header-for-exam" v-if="this.multipleFE == false">
              <span class="modal-header">Application ID</span>
              <div class="modal-header-for-exam-inside">
                <span class="text-bold text-indigo-7 " >
                  {{ this.form_int.applicationID[0] }}
                </span>
              </div>
          </div>
          <div class="row modal-header-for-exam" v-if="this.multipleFE == true">
            <span class="modal-header">Application ID</span>
            <div class="modal-header-for-exam-inside row justify-center ">
              <span  v-for="trackid in this.form_int.applicationID" :key="trackid" class="text-bold text-indigo-7 col-6 col-sm-4 border-multi" >
                {{ trackid }}
              </span>
            </div>
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="red" v-close-popup />
          <q-btn flat label="Yes" @click="schedForExam" color="indigo-7"  v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';
import { exportFile } from 'quasar';
import vueCookies from 'vue-cookies';

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  name: 'ForFinalInterviewNA',
  data() {
    return {
      sites: ['ALL','ORTIGAS', 'MARILAO'],
      site:'ALL',
      error_not_selected: "There is no selected applicants!",
      multipleFE: false,
      forExamDialog: false,
      filter: '',
      loadingModel: true,
      loadingText: '',
      loadingAction: false,
      viewDetailsDialog: false,
      actionDialog: false,
      forInterviewFailedDialog: false,
      forInterviewPassedDialog: false,
      applications: '',
      applicationsData: [],
      Pagination: { // 01182023
        sortBy: 'a_id',
        descending: 'ascending',
        // page: 1,
        rowsPerPage: 0
      },
      ResultColumns: [
        {
          name: 'action',
          field: 'action',
          label: 'Action',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        { // 01182023
          name: 'a_id',
          field: 'a_id',
          label: 'ID',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'site',
          field: 'site',
          label: 'Site',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'applicant_id',
          field: 'applicant_id',
          label: 'Applicant ID',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'firstname',
          field: 'firstname',
          label: 'First Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'middlename',
          field: 'middlename',
          label: 'Middle Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'lastname',
          field: 'lastname',
          label: 'Last Name',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'mobile',
          field: 'mobile',
          label: 'Contact Number',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'email',
          field: 'email',
          label: 'Email Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'presaddress',
          field: 'presaddress',
          label: 'Home Address',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'prescity',
          field: 'prescity',
          label: 'City',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'civil_status',
          field: 'civil_status',
          label: 'Civil Status',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'gender',
          field: 'gender',
          label: 'Gender',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_birth',
          field: 'date_of_birth',
          label: 'Birthdate',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'edattain',
          field: 'edattain',
          label: 'Highest Educational Attainment',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'course',
          field: 'course',
          label: 'Course',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'estrainee',
          field: 'estrainee',
          label: 'E.S.',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'account',
          field: 'account',
          label: 'Account',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'batch',
          field: 'batch',
          label: 'Batch',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_hired',
          field: 'date_hired',
          label: 'Date Hired',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'expected_pbdate',
          field: 'expected_pbdate',
          label: 'Expected PB Date',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'result',
          field: 'result',
          label: 'Result',
          sortable: true,
          style: 'width: 240px',
          align: 'center',
          headerStyle: 'width: 240px',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'date_of_application',
          field: 'date_of_application',
          label: 'Date Applied',
          sortable: true,
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'position_applying_for',
          field: 'position_applying_for',
          label: 'Position Applying For',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },
        {
          name: 'with_call_center_experience',
          field: 'with_call_center_experience',
          label: 'With Call Center Expereience?',
          style: 'width: 200px',
          align: 'center',
          headerStyle: 'width: 200px',
          headerClasses: 'bg-indigo-1'
        },
        { // 01182023
          name: 'reprofile_count',
          field: 'reprofile_count',
          label: 'Reprofile Count',
          align: 'center',
          headerClasses: 'bg-indigo-1'
        },

      ],
      applicationCounts: 0,
      vAD: {
        id: null,
        trackingID: null,
        dateApplied: null,
        name: null,
        presentAddress: null,
        permanentAddress: null,
        email: null,
        mobileNumber: null,
        telNumber: null,
        gender: null,
        birthday: null,
        civilStatus: null,
        religion: null,
        knowCompanyThru: null,
        positionApplyingFor: null,
        callCenterExp: null,
        ccExpDuration: null,
        ccExpCompany: null,
        ccExpPosition: null,
        ccExpAccount: null,
        ccExpReasonLeave: null,
        otherWorkExperience: null,
        owExpDuration: null,
        owExpCompany: null,
        owExpPosition: null,
        owExpBusNature: null,
        owExpReasonLeave: null,
        educAttainment: null,
        yearAttended: null,
        suc: null,
        course: null,
        yearLevel: null,
        natureOfIllness1: null,
        illnessDuration1: null,
        natureOfIllness2: null,
        illnessDuration2: null,
        natureOfIllness3: null,
        illnessDuration3: null,
        contactPerson: null,
        contactRelation: null,
        contactNumber: null,
        assessment: null,
        assessedBy: null,
        assessmentDate: null,
        lastProcess: null,
        onsite: null,
        wfh: null,
        trasportation: null,
        availabilityToJoin: null,
        pendingApplication: null,
        isp: null,
        speed: null,
        prevSalary: null,
        expSalary: null,
        account: null,
        position: null,
        recruiter: null,
        examDate: null,
        examResult: null,
        remarks: null,
      },
      form: {
        assessorAction: null,
        failedAssessment: null,
        assessorRemarks: null,
        appID: null
      },
      approveNotifType: '',
      int_result: '',
      form_int: {
        date: '',
        account_dept: '',
        interviewer: '',
        orientation_date: '',
        status: '',
        applicantID: [],
        applicationID: [],
      },
      dateTo: null,
      dateFrom: null,
      searchDateToFrom: {
          dateTo: null,
          dateFrom: null,
          status: 'Final Interview',
          site:'ALL'
      },
      forFinalInterviewSelected: [],
      multipleFinalInterview: [],
      notSelectedDialog: false,
      timer: "",
      // 01182023
      visibleColumns: ['action','site','applicant_id', 'firstname', 'middlename' , 'lastname', 'mobile',
      'email','presaddress', 'prescity','civil_status','gender','date_of_birth','edattain','course',
      'estrainee','account','batch', 'date_hired','expected_pbdate', 'result','date_of_application',
      'position_applying_for','with_call_center_experience','reprofile_count'],
      // END:: 01182023
    }
  },
  methods: {
    searchData: function(){
            vueCookies.set(this.searchKey, {
                'search': this.filter
            });
            //this.filter = this.searchKeyword;
        },
    loadApplications: function() {
      // console.log("cookie: ", document.cookie);
      // if(document.cookie != ''){
      //   let decodedCookie = decodeURIComponent(document.cookie);
      //   let ca = decodedCookie.split(';');
      //   let searchFilter = ca[0].substring(8, ca[0].length);
      //   this.filter = searchFilter;
      // }
      this.filter = this.searchKeyword;
      this.loadingModel = true;
      this.loadingText = 'Retrieving Applications';
      this.applications = [];
      this.applicationsData = [];
      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-applicant-listv2', {
          params: {
            status: 'Final Interview',
            passfail: '',
            code:'final_interview'
          }
        })
        .then((res) => {
          // console.log(res)
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;
          for (let eachApplication of this.applications) {
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            var status = "";
            if(eachApplication.passfail == 'Pass'){
              status = "For Final Interview";
            }else if(eachApplication.passfail == 'Fail'){
              status = "Delete";
            }else{
              status = "No results";
            }

            var account_telco = false;

            if(eachApplication.account != null){
              var account = eachApplication.account.toLowerCase();
              if(account.includes("telco")){
                account_telco = true;
              }else{
                account_telco = false;
              }
            }else{
              account_telco = false;
            }

            var edattn = '';
            if(eachApplication.edattain == "ed0"){
              edattn = "High School Graduate";
            } else if(eachApplication.edattain == "ed1"){
              edattn = "College Level";
            } else if(eachApplication.edattain == "ed2"){
              edattn = "Degree Holder";
            } else if(eachApplication.edattain == "ed3"){
              edattn = "Vocational Graduate";
            }else {
              edattn = eachApplication.edattain;
            }



            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: edattn,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              // ave_grade: eachApplication.ave_grade,
              status: status,
              telco: account_telco,
              reprofile_count: eachApplication.reprofile_count, // 01182023
              site: eachApplication.site
            });
          }
        })
        .finally(() => {
          this.loadingModel = false;
          this.loadingText = '';
        })
    },

    searchDate(){
      this.loading = true;
      let formData = new FormData();
      this.searchDateToFrom.dateFrom = this.dateFrom;
      this.searchDateToFrom.dateTo = this.dateTo;
      this.searchDateToFrom.site = this.site;
      formData.append('form_data', JSON.stringify(this.searchDateToFrom));
      axios.create({
          baseURL: this.apiUrl,
          headers: {
              'Authorization': `Bearer ${this.token}`
          }
          }).post('/recruitments/application/search-date', formData).then((res) => {
          console.log(res)
          this.applicationsData = [];
          this.applications = res.data.data;
          this.applicationCounts = Object.keys(this.applications).length;
          for (let eachApplication of this.applications) {
            // console.log(eachApplication)
            let name = eachApplication.lastname + ', ' + eachApplication.firstname + ' ' + eachApplication.middlename;
            let tempDate = eachApplication.date_apply;
            let date_applied = date.formatDate(tempDate, 'MMMM DD, YYYY');
            let date_exam = date.formatDate(eachApplication.exam_date, 'MMMM DD, YYYY');

            var status = "";
            if(eachApplication.passfail == 'Pass'){
              status = "For Final Interview";
            }else if(eachApplication.passfail == 'Fail'){
              status = "Delete";
            }else{
              status = "No results";
            }

          // 01182023

            var account_telco = false;
            if(eachApplication.account != null){
              var account = eachApplication.account.toLowerCase();
              if(account.includes("telco")){
                account_telco = true;
              }else{
                account_telco = false;
              }
            }else{
              account_telco = false;
            }

          // 01182023
            var edattn = '';
            if(eachApplication.edattain == "ed0"){
              edattn = "High School Graduate";
            } else if(eachApplication.edattain == "ed1"){
              edattn = "College Level";
            } else if(eachApplication.edattain == "ed2"){
              edattn = "Degree Holder";
            } else if(eachApplication.edattain == "ed3"){
              edattn = "Vocational Graduate";
            }else {
              edattn = eachApplication.edattain;
            }



            this.applicationsData.push({
              id: eachApplication.id,
              tracking_number: eachApplication.tracking_number,
              trainee_id: eachApplication.trainee_id,
              firstname: eachApplication.firstname,
              middlename: eachApplication.middlename,
              lastname: eachApplication.lastname,
              mobile: eachApplication.mobile,
              email: eachApplication.email,
              presaddress: eachApplication.presaddress,
              prescity: eachApplication.prescity,
              civil_status: eachApplication.civil_status,
              gender: eachApplication.gender,
              edattain: edattn,
              course: eachApplication.course,
              estrainee: eachApplication.estrainee,
              account: eachApplication.account,
              batch: eachApplication.batch,
              date_hired: eachApplication.date_hired,
              expected_pbdate: eachApplication.expected_pbdate,
              result: eachApplication.result,
              date_of_application: date_applied,
              position_applying_for: eachApplication.position_sought,
              with_call_center_experience: eachApplication.ccexperience,
              applicant_id: eachApplication.tracking_number,
              exam_date: date_exam,
              passfail: eachApplication.passfail_int,
              // ave_grade: eachApplication.ave_grade,
              inactive: eachApplication.inactive,
              // ave_grade: eachApplication.ave_grade,
              status: status,
              telco: account_telco, // 01182023
              reprofile_count: eachApplication.reprofile_count, // 01182023
              site: eachApplication.site
            });

          }
      });
    },

    interviewResult: function(rowID, applicantID, status){
      //console.log("rowID: ", rowID);
      // console.log("applicantID: ", applicantID);
      // console.log("status: ", status);

      this.form_int.applicantID.push(rowID);
      this.form_int.applicationID.push(applicantID);

      this.int_result = status;
      if(status == 'Failed'){
        this.forInterviewFailedDialog = true;
        this.forInterviewPassedDialog = false;
      }else{
        this.forInterviewPassedDialog = true;
        this.forInterviewFailedDialog = false;
      }

    },

    interview: function(status){
      // console.log("status: ",status)
      console.log("form_int: ",this.form_int)

      this.form_int.status = status;
      this.loading = true;
      let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form_int));
        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/recruitments/application/update-for-interviewDetails', formData).then((res) => {
          setTimeout(() => {
            // console.log(res);
            this.form_int.date = '';
            this.form_int.account_dept = '';
            this.form_int.interviewer = '';
            this.form_int.orientation_date = '';
            this.form_int.status = '';
            this.form_int.applicantID = [];
            this.form_int.applicationID = [];
            this.loadApplications();
            this.loading = false;
            this.forFinalInterviewSelected = [];
          }, 1000);
        });

    },

    viewDetails: function(applicationStatus, status, rowID) {
      this.loadingModel = true;
      this.loadingText = 'Retrieving Details';

      axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        })
        .get('/recruitments/application/get-details-list', {
          params: {
            id: rowID,
            status: applicationStatus,
            dump: status,
          }
        })
        .then((res) => {
          this.loadingModel = false;
          var viewApplicantDetails = res.data.data[0];

          // console.log(viewApplicantDetails);

          var temp_date_applied, temp_birthdate;

          this.vAD.id = viewApplicantDetails.a_id;

          this.vAD.trackingID = viewApplicantDetails.tracking_number;
          this.vAD.dateApplied = date.formatDate(viewApplicantDetails.date_apply, 'MMMM DD, YYYY');

          this.vAD.name = this.toTitleCase(viewApplicantDetails.firstname) + ' ' + this.toTitleCase(viewApplicantDetails.middlename) + ' ' +
            this.toTitleCase(viewApplicantDetails.lastname);

          this.vAD.presentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

          this.vAD.permanentAddress = this.toTitleCase(viewApplicantDetails.presaddress) + ', ' + this.toTitleCase(viewApplicantDetails.prescity);

          this.vAD.email = viewApplicantDetails.email.toLowerCase();

          this.vAD.mobileNumber = viewApplicantDetails.mobile;

          this.vAD.telNumber = viewApplicantDetails.telephone;

          this.vAD.gender = this.toTitleCase(viewApplicantDetails.gender);

          this.vAD.birthday = date.formatDate(viewApplicantDetails.date_of_birth, 'MMMM DD, YYYY');

          this.vAD.civilStatus = this.toTitleCase(viewApplicantDetails.civil_status);

          this.vAD.religion = this.toTitleCase(viewApplicantDetails.religion);

          //this.vAD.through = this.toTitleCase(viewApplicantDetails.through) + ': ' + this.toTitleCase(viewApplicantDetails.through);

          if (viewApplicantDetails.through == "job posting" || viewApplicantDetails.through == "Referral" || viewApplicantDetails.through == 'OTHERS') {
            this.vAD.through = this.toTitleCase(viewApplicantDetails.through) + ': ' + this.toTitleCase(viewApplicantDetails.thru);
          } else {
            this.vAD.through = this.toTitleCase(viewApplicantDetails.through);
          }

          this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_sought);
          // if (viewApplicantDetails.position_applying_for == 'OTHERS') {
          //   this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_applying_for) + ': ' + this.toTitleCase(viewApplicantDetails.other_position);
          // } else {
          //   this.vAD.positionApplyingFor = this.toTitleCase(viewApplicantDetails.position_applying_for);
          // }

          if (viewApplicantDetails.ccexperience == 'with call center experience') {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
            this.vAD.ccExpDuration = this.toTitleCase(viewApplicantDetails.ccyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.ccmonthexp);
            this.vAD.ccExpCompany = this.toTitleCase(viewApplicantDetails.cc_compname);
            this.vAD.ccExpPosition = this.toTitleCase(viewApplicantDetails.position);
            //this.vAD.ccExpAccount = this.toTitleCase(viewApplicantDetails.cc_exp_account_handled);
            this.vAD.ccExpReasonLeave = this.toTitleCase(viewApplicantDetails.reason_for_leaving);
          } else {
            this.vAD.callCenterExp = this.toTitleCase(viewApplicantDetails.ccexperience);
          }

          if (viewApplicantDetails.othexp == 'YES') {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
            this.vAD.owExpDuration = this.toTitleCase(viewApplicantDetails.othyrsexp) + ' & ' + this.toTitleCase(viewApplicantDetails.othmonthexp);
            // this.vAD.owExpCompany = this.toTitleCase(viewApplicantDetails.other_exp_company_name);
            // this.vAD.owExpPosition = this.toTitleCase(viewApplicantDetails.other_exp_position);
            // this.vAD.owExpBusNature = this.toTitleCase(viewApplicantDetails.other_exp_nature_business);
            // this.vAD.owExpReasonLeave = this.toTitleCase(viewApplicantDetails.other_exp_reason_leaving);
          } else {
            this.vAD.otherWorkExperience = this.toTitleCase(viewApplicantDetails.othexp);
          }

          var edattn = '';
          if(viewApplicantDetails.edattain == "ed0"){
            edattn = "High School Graduate";
          } else if(viewApplicantDetails.edattain == "ed1"){
            edattn = "College Level";
          } else if(viewApplicantDetails.edattain == "ed2"){
            edattn = "Degree Holder";
          } else if(viewApplicantDetails.edattain == "ed3"){
            edattn = "Vocational Graduate";
          }

          this.vAD.educAttainment = this.toTitleCase(edattn);

          this.vAD.yearAttended = viewApplicantDetails.school_year;

          // this.vAD.suc = this.toTitleCase(viewApplicantDetails.suc);

          // if (viewApplicantDetails.educational_background == 'DEGREE HOLDER' || viewApplicantDetails.educational_background == 'VOCATIONAL COURSE') {
          //   this.vAD.course = this.toTitleCase(viewApplicantDetails.course);
          // }

          // if (viewApplicantDetails.educational_background == 'COLLEGE LEVEL') {
          //   this.vAD.course = this.toTitleCase(viewApplicantDetails.course);
          //   this.vAD.yearLevel = this.toTitleCase(viewApplicantDetails.year_level);
          // }

          this.vAD.natureOfIllness1 = viewApplicantDetails.illness;

          // if (viewApplicantDetails.nature_of_illness1 == 'N/A') {
          //   this.vAD.illnessDuration1 = 'N/A';
          // } else {
          //   this.vAD.illnessDuration1 = this.toTitleCase(viewApplicantDetails.approx_from_month1) + ' ' + viewApplicantDetails.approx_from_year1 +
          //     ' to ' + this.toTitleCase(viewApplicantDetails.approx_to_month1) + ' ' + viewApplicantDetails.approx_to_year1;
          // }

          // this.vAD.natureOfIllness2 = viewApplicantDetails.nature_of_illness2;

          // if (viewApplicantDetails.nature_of_illness2 == 'N/A') {
          //   this.vAD.illnessDuration2 = 'N/A';
          // } else {
          //   this.vAD.illnessDuration2 = this.toTitleCase(viewApplicantDetails.approx_from_month2) + ' ' + viewApplicantDetails.approx_from_year2 +
          //     ' to ' + this.toTitleCase(viewApplicantDetails.approx_to_month2) + ' ' + viewApplicantDetails.approx_to_year2;
          // }

          // this.vAD.natureOfIllness3 = viewApplicantDetails.nature_of_illness3;

          // if (viewApplicantDetails.nature_of_illness3 == 'N/A') {
          //   this.vAD.illnessDuration3 = 'N/A';
          // } else {
          //   this.vAD.illnessDuration3 = this.toTitleCase(viewApplicantDetails.approx_from_month3) + ' ' + viewApplicantDetails.approx_from_year3 +
          //     ' to ' + this.toTitleCase(viewApplicantDetails.approx_to_month3) + ' ' + viewApplicantDetails.approx_to_year3;
          // }

          // this.vAD.contactPerson = this.toTitleCase(viewApplicantDetails.emergency_contact_name);

          // this.vAD.contactRelation = this.toTitleCase(viewApplicantDetails.emergency_contact_relation);

          // this.vAD.contactNumber = viewApplicantDetails.emergency_contact_number;

          // this.vAD.assessment = viewApplicantDetails.ongoing_assessment;

          // this.vAD.assessedBy = viewApplicantDetails.assessor_firstname + ' ' + viewApplicantDetails.assessor_lastname;

          // this.vAD.assessmentDate = viewApplicantDetails.date_assessed;

          // this.vAD.lastProcess = viewApplicantDetails.last_process;

          this.viewDetailsDialog = true;
        })
    },

    actionForm: function(action) {
      this.form.assessorAction = action;
      this.form.appID = this.vAD.id;
      this.actionDialog = true;
    },

    processAssessment: function() {
      if (this.form.assessorAction != 'Failed' && this.form.assessorRemarks == null) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please put assessment / remarks for this application'
        });
      } else if (this.form.assessorAction == 'Failed' && (this.form.failedAssessment == null || this.form.assessorRemarks == null)) {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please select option and put assessment / remarks for this application'
        });
      } else {
        this.loadingAction = true;
        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/recruitments/application/assessment', formData).then((res) => {
          setTimeout(() => {
            this.loadingAction = false;
            this.actionDialog = false;
            this.viewDetailsDialog = false;
            this.applicationsData = [];
            this.loadApplications();
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-file-signature',
              message: 'Assessment Done'
            });
            this.form.assessorAction = null;
            this.form.assessorRemarks = null;
            this.form.failedAssessment = null;
            this.form.appID = null;
          }, 1000);
        });
      }
    },

    exportTable () {
      // naive encoding to csv format
      const content = [ this.ResultColumns.map(col => wrapCsvValue(col.label)) ].concat(
        this.applicationsData.map(row => this.ResultColumns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'ForFinalInterview.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    },

    singleForExam(id, applicant_id){
      console.log(id);
      console.log(applicant_id);
      this.form_int.applicantID.push(id);
      this.form_int.applicationID.push(applicant_id);
      this.forExamDialog = true;
    },

    multipleForExam(){
      // console.log("checkBox:", this.forFinalInterviewSelected)
      var error = false;

      if(this.forFinalInterviewSelected.length > 0){
        this.form_int.applicantID = [];

        if(this.forFinalInterviewSelected.length == 1){
          this.multipleFE = false;
        }else {
          this.multipleFE = true;
        }

        var showError = false;
        for(var i = 0; i < this.forFinalInterviewSelected.length;i++){
          if(this.forFinalInterviewSelected[i].telco == false){
            this.error_not_selected = "You selected an Applicant that already taken an exam!"
            this.notSelectedDialog = true;
            showError = true;
            break;
          }else{
            this.form_int.applicantID.push(this.forFinalInterviewSelected[i].id);
            this.form_int.applicationID.push(this.forFinalInterviewSelected[i].tracking_number);
          }
        }

        console.log("form_int:", this.form_int);
        // console.log(this.form_int.applicantID)
        if(showError == false){
          this.forExamDialog = true;
        }


      }else{
        this.error_not_selected = "There is no selected applicants!";
        this.notSelectedDialog = true;
      }
    },

    schedForExam(){
      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.form_int));
      axios.create({
        baseURL: this.apiUrl,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }).post('/recruitments/application/telco-sched-exam', formData).then((res) => {
        setTimeout(() => {
          // console.log(res);
          this.forFinalInterviewSelected = [];
          this.loadApplications();
        }, 1000);
      });
    },

    multipleForFinalInterview(status) {
      // console.log(status)
      console.log("checkBox:", this.forFinalInterviewSelected)
      var error = false;
      if(this.forFinalInterviewSelected.length > 0){
        this.form_int.applicantID = [];

        var showError = false;
        for(var i = 0; i < this.forFinalInterviewSelected.length;i++){
          if(this.forFinalInterviewSelected[i].telco == true){
            this.error_not_selected = "You selected an Applicant that you cannot passed the final interview! "
            this.notSelectedDialog = true;
            showError = true;
            break;
          }else{
            this.form_int.applicantID.push(this.forFinalInterviewSelected[i].id);
            this.form_int.applicationID.push(this.forFinalInterviewSelected[i].tracking_number);
          }
        }
        // console.log(this.form_int.applicantID)

        if(status == 'Failed'){
          this.forInterviewFailedDialog = true;
          this.forInterviewPassedDialog = false;
        }else{
          if(showError == false){
            this.forInterviewPassedDialog = true;
            this.forInterviewFailedDialog = false;
          }
        }
      }else{
        this.error_not_selected = "There is no selected applicants!";
        this.notSelectedDialog = true;
      }
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },

  mounted() {
    this.loadApplications();
    this.timer = setInterval(this.loadApplications, 300000);
  },

  beforeUnmount() {
    this.cancelAutoUpdate();
  },
}
</script>
<style scoped>
.sticky {
  height: 80px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  top: 0;
  z-index: 10;
}

.sticky div {
  width: 100%;
}

.btn-height {
  height: 40px;
}

.text-head {
  font-size: 16px;
}

.modal-header{
  border-bottom: solid 2px #3949ab;
  text-align: center;
  width: 100%;
  font-size: 20px;
  background: #3949ab;
  color: white;
}

.modal-header-for-exam {
  border: solid 2px #3949ab;
  margin-bottom: 10px;
  text-align: center;
}

.modal-header-for-exam-inside {
  width: 100%;
  padding: 10px;
  font-size: 17px;
}

.border-multi {
  border: solid 1px;
}
</style>

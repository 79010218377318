<template>
  <div class="q-gutter-sm q-pa-lg">
    <div class="row">
      <div class="row q-mb-md full-width">
        <div class="full-width" style="height: 40px;">
          <h4 class="q-ma-none row inline">Half Day</h4>
          <q-banner dense rounded class="bg-orange text-white float-right">
            <template v-slot:avatar>
              <q-icon name="fa fa-info-circle" color="white" />
            </template>
            Shift hours covered is {{ halfShift }} hours.
          </q-banner>
        </div>
      </div>
      <q-form class="full-width">
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Date</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-12">
                  <q-input v-model="dateToday" standout="bg-red-7 text-white" color="red-7" readonly />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Inclusive Time</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div class="row">
                <div class="col-6 q-pr-sm">Original Time-In</div>
                <div class="col-6 q-pl-sm">Original Time-Out</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-input :value="originalTimeIn" outlined color="red-7" readonly>
                    <template v-slot:append>
                      <q-icon name="fa fa-clock" class="cursor-pointer">
                        <q-popup-proxy transition-show="flip-down" transition-hide="flip-up">
                          <q-time v-model="form.selectedOriginalTimeIn" :minute-options="minuteOptions" landscape color="red-7" minimal>
                            <div class="row items-center justify-end q-gutter-xs">
                              <q-btn label="Cancel" color="red-7" style="width: 80px" flat v-close-popup />
                              <q-btn label="OK" color="red-7" style="width: 80px" flat @click="saveTime(form.selectedOriginalTimeIn)" v-close-popup />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
                <div class=" col-6 q-pl-sm">
                  <q-input v-model="originalTimeOut" standout="bg-red-7 text-white" color="red-7" readonly />
                </div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-md">
              <div class="row">
                <div class="col-6 q-pr-sm">Halfday Time-In</div>
                <div class="col-6 q-pl-sm">Halfday Time-Out</div>
              </div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none">
              <div class="row ">
                <div class="col-6 q-pr-sm">
                  <q-select v-model="newTimeIn" @input="setHalfTime(newTimeIn)" :options="hdOptions" options-cover outlined color="red-7" />
                </div>
                <div class=" col-6 q-pl-sm">
                  <q-input v-model="newTimeOut" standout="bg-red-7 text-white" color="red-7" readonly />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="full-width row justify-center q-ma-md">
          <q-card bordered class="my-card col-8 q-pa-md">
            <q-card-section class="q-pa-none q-ma-none">
              <div class="text-h6">Reason</div>
            </q-card-section>
            <q-card-section class="q-pa-none q-ma-none q-mt-sm">
              <div style="width: 100%; max-height: 150px">
                <q-input type="textarea" v-model="form.halfdayReason" outlined color="red-7" maxlength="250" clearable bottom-slots counter hint="Max 250 characters" />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <!--<div class="full-width row justify-center q-ma-md">
            <strong>ISO NUMBER: HR-007 REV.00</strong>
         </div>-->
      </q-form>
      <div class="full-width row justify-center q-ma-md">
        <div class="row">
          <div class="col-12 q-mt-md">
            <q-btn class="col-4 q-pa-none q-ma-md" @click="resetForm" size="lg" style="width: 200px" push color="red-7">RESET</q-btn>
            <q-btn class="col-4 q-pa-none q-ma-md" @click="submitApplication" :loading="loading" size="lg" style="width: 200px" push color="red-7">SUBMIT
              <template v-slot:loading>
                <q-spinner-hourglass class="on-left" />
                Sending...
              </template>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { date } from 'quasar';

let today = Date.now();
let dateToday = 'Today: ' + date.formatDate(today, ' dddd,  MMMM DD, YYYY');
const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export default {
  name: 'Halfday',
  props: ['value'],
  data() {
    return {
      shiftHours: 0,
      halfShift: 0,
      loading: false,
      dateToday,
      form: {
        halfdayDate: '',
        selectedOriginalTimeIn: '',
        selectedOriginalTimeOut: '',
        halfdayNewTimeIn: '',
        halfdayNewTimeOut: '',
        halfdayReason: ''
      },
      originalTimeIn: 'Please select a time...',
      originalTimeOut: 'Please select original time-in...',
      newTimeIn: 'Please select original time-in...',
      newTimeOut: 'Please select original time-in...',
      minuteOptions: [0, 30],
      hdOptions: [],
    }
  },
  methods: {
    saveTime: function(timeSent) {
      this.shiftHours = this.userData.shift_hours;
      var tempTime = timeSent.split(':');
      var newTime = date.buildDate({ hours: tempTime[0], minutes: tempTime[1] });
      var oNewTimeIn = date.formatDate(newTime, 'hh:mm A');
      var hdNewTimeInPlus = date.addToDate(newTime, { hours: this.halfShift });
      var hdNewTimeIn = date.formatDate(hdNewTimeInPlus, 'hh:mm A');
      var oNewTimeOutPlus = date.addToDate(newTime, { hours: this.shiftHours });
      var oNewTimeOut = date.formatDate(oNewTimeOutPlus, 'hh:mm A');

      this.originalTimeIn = oNewTimeIn;
      this.originalTimeOut = oNewTimeOut;
      this.newTimeIn = 'Please select halfday time-in...';
      this.newTimeOut = 'Please select halfday time-in...';
      this.hdOptions = [oNewTimeIn, hdNewTimeIn];
      this.form.selectedOriginalTimeOut = date.formatDate(oNewTimeOutPlus, 'HH:mm');
    },

    setHalfTime: function(startTime) {
      var newDate = date.extractDate(startTime, 'hh:mm A');
      var hdNewTimeOutPlus = date.addToDate(newDate, { hours: this.halfShift });
      var hdNewTimeOut = date.formatDate(hdNewTimeOutPlus, 'hh:mm A');

      this.newTimeOut = hdNewTimeOut;
      this.form.halfdayNewTimeIn = date.formatDate(newDate, 'HH:mm');
      this.form.halfdayNewTimeOut = date.formatDate(hdNewTimeOutPlus, 'HH:mm');
    },

    submitApplication: function() {
      this.form.halfdayDate = date.formatDate(today, 'YYYY-MM-DD');

      if (this.form.selectedOriginalTimeIn == '' || this.form.halfdayNewTimeIn == '' ||
        this.form.halfdayReason == '') {
        this.$q.notify({
          color: 'red-6',
          textColor: 'white',
          icon: 'fa fa-exclamation-circle',
          message: 'Please fill-in all necessary details.'
        });
      } else {
        this.loading = true;

        let formData = new FormData();
        formData.append('form_data', JSON.stringify(this.form));

        axios.create({
          baseURL: this.apiUrl,
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).post('/online-forms/applications/halfday', formData).then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$q.notify({
              color: 'green-7',
              textColor: 'white',
              icon: 'fa fa-shipping-fast',
              message: 'Halfday Application Submitted'
            });
            this.resetForm();
          }, 1000);
        });
      }
    },

    resetForm: function() {
      var self = this;
      Object.keys(this.form).forEach(function(key, index) {
        self.form[key] = '';
      });
      this.originalTimeIn = 'Please select a time...';
      this.originalTimeOut = 'Please select original time-in...';
      this.newTimeIn = 'Please select original time-in...';
      this.newTimeOut = 'Please select original time-in...';
      setTimeout(() => {
        scrollToTop();
      }, 200);
    }
  },
  mounted() {
    this.halfShift = this.userData.shift_hours / 2;
  }
}
</script>
<style scoped>
</style>

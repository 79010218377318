<template>

  <div class="q-pa-md">
      <q-form @submit="onSubmit">
        <div class="row items-start">
          <div class="row inline q-pa-md">
             <q-input outlined  style="width:350px;"  type="text" v-model="employeeID" label="Search Employee ID" :rules="[formRules.required]" ></q-input>
           </div>
           <div class="row inline q-pa-md">
             <q-select outlined v-model="year" :options="years" label="Years" style="width: 200px" :rules="[formRules.required]" />
          </div>
          <div class="row inline q-pa-md">
            <q-btn outline style="height: 54px; color: green;" label="Search History" type="submit" />
          </div>
        </div>
      </q-form>
      <q-table :data="data" :columns="columns" row-key="id">
        <q-tr slot="body" slot-scope="props" :props="props">
          <q-td key="leaveType" :props="props">{{ props.row.leaveType }}</q-td>
          <q-td key="fromDate" :props="props">{{ props.row.fromDate }}</q-td>
          <q-td key="toDate" :props="props">{{ props.row.toDate }}</q-td>
          <q-td key="total" :props="props">{{ props.row.total }}</q-td>
          <q-td key="reason" :props="props">{{ props.row.reason }}</q-td>
          <q-td key="status" :props="props">
            <q-chip outline  text-color="white"
            :color="props.row.status == 'Pending' ? 'orange' : props.row.status == 'Approved' ?  'teal': props.row.status == 'Done' ?  'green': props.row.status == 'Declined' ?  'red': ''  "
            :icon="props.row.status == 'Pending' ? 'fa fa-clock' : props.row.status == 'Approved' ?  'fa fa-thumbs-up': props.row.status == 'Done' ?  'fa fa-check': props.row.status == 'Declined' ?  'fa fa-thumbs-down': ''  ">
              {{ props.row.status }}
            </q-chip>
          </q-td>

          <q-td key="name" :props="props">{{ props.row.name }}</q-td>
          <q-td key="dept" :props="props">{{ props.row.dept }}</q-td>
          <q-td key="position" :props="props">{{ props.row.position }}</q-td>
        </q-tr>
        <template v-slot:no-data>
          <div class="full-width row flex-center text-amber-10 q-gutter-sm">
            <span>No Result Found!</span><q-icon size="1.5em" name="fa fa-exclamation-triangle" />
          </div>
        </template>
        <template v-slot:top-right>
          <q-btn
            color="primary"
            icon-right="fas fa-download"
            label="Export to csv"
            no-caps
            @click="exportTable"
          />
        </template>
      </q-table>

      <q-dialog v-model="spinner" persistent >
          <q-card class="my-card transparent no-shadow">
              <q-card-section >
                  <q-spinner-gears color="cyan"  size="5.5em"/>
              </q-card-section>
          </q-card>
      </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { exportFile } from 'quasar'

function wrapCsvValue (val, formatFn, row) {
  let formatted = formatFn !== void 0
    ? formatFn(val, row)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  data () {
    return {
        years: [],
        year: new Date().getFullYear(),
        employeeID: '',
        columns: [
        { name: 'leaveType', align: 'center', label: 'Leave Type', field: 'leaveType'},
        { name: 'fromDate', align: 'center', label: 'From Date', field: 'fromDate'},
        { name: 'toDate', align: 'center', label: 'To Date', field: 'toDate' },
        { name: 'total', align: 'center', label: 'Total Days', field: 'total' },
        { name: 'reason', align: 'center', label: 'Reason', field: 'reason' },
        { name: 'status', align: 'center', label: 'Status', field: 'status' },
        { name: 'name', align: 'center', label: 'Name', field: 'name' },
        { name: 'dept', align: 'center', label: 'Department', field: 'dept' },
        { name: 'position', align: 'center', label: 'Position', field: 'position' },
      ],
      data: [],
      spinner:false,
   }
},

   created(){
      this.yearsList()
   },

  methods: {
      onSubmit () {
          this.spinner = true;
          let formData = new FormData();
          formData.append("employeeID", this.employeeID);
          formData.append("year", this.year);
          axios.create({
              baseURL : this.apiUrl,
              headers : {'Authorization' : `Bearer ${this.token}`}
          }).post('/leave/history',formData)
          .then((res) => {
              this.data = res.data.data
              this.spinner = false
          });

      },

      exportTable () {
        // naive encoding to csv format
        const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
          this.data.map(row => this.columns.map(col => wrapCsvValue(
            typeof col.field === 'function'
              ? col.field(row)
              : row[col.field === void 0 ? col.name : col.field],
            col.format
          )).join(','))
        ).join('\r\n')

        const status = exportFile(
          'SLVLRemainingReport.csv',
          content,
          'text/csv'
        )

        if (status !== true) {
          this.$q.notify({
            message: 'Browser denied file download...',
            color: 'negative',
            icon: 'warning'
          })
       }
    },

   yearsList : function(){
      axios.create({
        baseURL : this.apiUrl,
        headers : {'Authorization' : `Bearer ${this.token}`}
      }).post(`/timekeeping/years/list`)
      .then((res) => {
        this.years = res.data.data
      });
   },

  },
}
</script>

<template>

  <div class="q-pa-md">
      <q-form @submit="onSubmit">
        <div class="row items-start">
          <div class="row inline q-pa-md">
              <q-input filled outlined v-model="fromDate" mask="date" :rules="['date']" label="Date From">
                  <template v-slot:append>
                      <i class="far fa-calendar-alt cursor-pointer" >
                          <q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
                              <q-date v-model="fromDate" @input="() => $refs.dhDateProxy.hide()" />
                          </q-popup-proxy>
                      </i>
                  </template>
              </q-input>
          </div>
          <div class="row inline q-pa-md">
              <q-input filled outlined v-model="toDate" mask="date" :rules="['date']" label="Date To">
                  <template v-slot:append>
                      <i class="far fa-calendar-alt cursor-pointer" >
                          <q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
                              <q-date v-model="toDate" @input="() => $refs.dhDateProxy.hide()" />
                          </q-popup-proxy>
                      </i>
                  </template>
              </q-input>
          </div>
          <div class="row inline q-pa-md">
            <q-select filled v-model="status" :options="statusOptions" label="Status" style="width: 200px" class="q-mr-sm col" :rules="[formRules.required]"/>
          </div>
           <div class="row inline q-pa-md">
              <q-input filled type="text" v-model="employeeID" label="Employee ID (optional)" lazy-rules >
              </q-input>
            </div>
          <div class="row inline q-pa-md">
            <q-btn outline style="height: 54px; color: green;" label="Filter" type="submit" />
          </div>
        </div>
      </q-form>
      <q-table :data="data" :columns="columns" row-key="id" :pagination="initialPagination">
        <q-tr slot="body" slot-scope="props" :props="props">
          <q-td key="dept" :props="props">{{ props.row.dept }}</q-td>
          <q-td key="empID" :props="props">{{ props.row.empID }}</q-td>
          <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
          <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
          <q-td key="vlcredit" :props="props">{{ props.row.vlcredit }}</q-td>
          <q-td key="slcredit" :props="props">{{ props.row.slcredit }}</q-td>
          <q-td key="vlused" :props="props">{{ props.row.vlused }}</q-td>
          <q-td key="slused" :props="props">{{ props.row.slused }}</q-td>
          <q-td key="vlremain" :props="props">{{ props.row.vlremain }}</q-td>
          <q-td key="slremain" :props="props">{{ props.row.slremain }}</q-td>
        </q-tr>
        <template v-slot:no-data>
          <div class="full-width row flex-center text-amber-10 q-gutter-sm">
            <span>No Result Found!</span><q-icon size="1.5em" name="fa fa-exclamation-triangle" />
          </div>
        </template>
        <template v-slot:top-right>
          <q-btn
            color="primary"
            icon-right="fas fa-download"
            label="Export to csv"
            no-caps
            @click="exportTable"
          />
        </template>
      </q-table>

      <q-dialog v-model="spinner" persistent >
          <q-card class="my-card transparent no-shadow">
              <q-card-section >
                  <q-spinner-gears color="cyan"  size="5.5em"/>
              </q-card-section>
          </q-card>
      </q-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import { exportFile } from 'quasar'

function wrapCsvValue (val, formatFn) {
  let formatted = formatFn !== void 0
    ? formatFn(val)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  data () {
    return {
        initialPagination: {
            //sortBy: 'desc',
            //descending: false,
            //page: 2,
            rowsPerPage: 50
          },
        fromDate: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
        toDate: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
        status:'',
        employeeID:'',
        statusOptions: ['ACTIVE','HOLD','INACTIVE'],
        columns: [
        { name: 'dept', align: 'center', label: 'DEPARTMENT', field: 'dept' },
        { name: 'empID', align: 'center', label: 'EMPLOYEE ID', field: 'empID' },
        { name: 'firstname', align: 'center', label: 'FIRSTNAME', field: 'firstname' },
        { name: 'lastname', align: 'center', label: 'LASTNAME', field: 'lastname' },
        { name: 'vlcredit', align: 'center', label: 'VL CREDITS', field: 'vlcredit' },
        { name: 'slcredit', align: 'center', label: 'SL CREDITS', field: 'slcredit' },
        { name: 'vlused', align: 'center', label: 'VL USED', field: 'vlused' },
        { name: 'slused', align: 'center', label: 'SL USED', field: 'slused' },
        { name: 'vlremain', align: 'center', label: 'VL REMAIN', field: 'vlremain' },
        { name: 'slremain', align: 'center', label: 'SL REMAIN', field: 'slremain' },
      ],
      data: [],
      spinner:false,
    }
},

created(){
    //this.leaveList()
},

    methods: {
        onSubmit () {
            this.spinner = true
            let formData = new FormData();
                formData.append("fromDate", this.fromDate);
                formData.append("toDate", this.toDate);
                formData.append("status", this.status);
                formData.append("employeeID", this.employeeID);
            axios.create({
                baseURL : this.apiUrl,
                headers : {'Authorization' : `Bearer ${this.token}`}
            }).post('/leave/remaining/credits',formData)
            .then((res) => {
                this.data = res.data.data

                console.log(res.data.data)
                this.spinner = false
            });

        },

    exportTable () {
      // naive encoding to csv format
      const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
        this.data.map(row => this.columns.map(col => wrapCsvValue(
          typeof col.field === 'function'
            ? col.field(row)
            : row[col.field === void 0 ? col.name : col.field],
          col.format
        )).join(','))
      ).join('\r\n')

      const status = exportFile(
        'SLVLRemainingReport.csv',
        content,
        'text/csv'
      )

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        })
      }
    }
  },

}
</script>

<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px"/>
					<q-select v-model="ratefilter" :options="rateOptions" label="Type"  style="width: 200px"/>
					<q-btn color="primary" label="Filter" @click="generateDTR"/>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
			<q-table :dense="$q.screen.lt.md" :grid="$q.screen.xs" title="TK Summary" :data="generatedData" :columns="columns"
			:pagination.sync="pagination" row-key="id" separator="cell"
			no-data-label="I didn't find anything for you!" :loading="loading" :filter="filter"
			@request="onRequest" binary-state-sort>
			<template v-slot:header="props">
				<q-tr :props="props">
					<q-th v-for="col in props.cols" :key="col.name" :props="props">
						{{ col.label }}
						<q-tooltip anchor="top middle" content-class="bg-red" :offset="[10, 10]">
							{{ col.desc }}
						</q-tooltip>
					</q-th>
				</q-tr>
			</template>
			<template v-slot:body="props">
				<q-tr :props="props" style="cursor: pointer;">
					<q-td key="action" :props="props">
						 <div>
							<q-btn round outline  color="orange" @click="selectEmployee(props.row)">
								<i class="fas fa-edit"></i>
								<q-tooltip anchor="top middle" content-class="bg-orange" self="bottom middle" :offset="[10, 10]" color="orange">
									<strong>EDIT {{ props.row.name }}</strong>
								</q-tooltip>
							</q-btn>
						</div>
					</q-td>
					<q-td key="empID" :props="props">{{ props.row.empID }}</q-td>
					<q-td key="name" :props="props">{{ props.row.name }}</q-td>
					<q-td key="REG_HOUR" :props="props">{{ props.row.REG_HOUR }}</q-td>
					<q-td key="UNDER_TIME" :props="props">{{ props.row.UNDER_TIME }}</q-td>
					<q-td key="ABSENT" :props="props">{{ props.row.ABSENT }}</q-td>
					<q-td key="OT" :props="props">{{ props.row.OT }}</q-td>
					<q-td key="RD" :props="props">{{ props.row.RD }}</q-td>
					<q-td key="OT_RD" :props="props">{{ props.row.OT_RD }}</q-td>
					<q-td key="SH" :props="props">{{ props.row.SH }}</q-td>
					<q-td key="OT_SH" :props="props">{{ props.row.OT_SH }}</q-td>
					<q-td key="SH_RD" :props="props">{{ props.row.SH_RD }}</q-td>
					<q-td key="OT_RD_SH" :props="props">{{ props.row.OT_RD_SH }}</q-td>
					<q-td key="LH" :props="props">{{ props.row.LH }}</q-td>
					<q-td key="OT_LH" :props="props">{{ props.row.OT_LH }}</q-td>
					<q-td key="LH_RD" :props="props">{{ props.row.LH_RD }}</q-td>
					<q-td key="OT_RD_LH" :props="props">{{ props.row.OT_RD_LH }}</q-td>
					<q-td key="ND" :props="props">{{ props.row.ND }}</q-td>
					<q-td key="OT_ND" :props="props">{{ props.row.OT_ND }}</q-td>
					<q-td key="ND_RD" :props="props">{{ props.row.ND_RD }}</q-td>
					<q-td key="OT_ND_RD" :props="props">{{ props.row.OT_ND_RD }}</q-td>
					<q-td key="ND_SH" :props="props">{{ props.row.ND_SH }}</q-td>
					<q-td key="OT_ND_SH" :props="props">{{ props.row.OT_ND_SH }}</q-td>
					<q-td key="ND_SH_RD" :props="props">{{ props.row.ND_SH_RD }}</q-td>
					<q-td key="ND_LH" :props="props">{{ props.row.ND_LH }}</q-td>
					<q-td key="ND_LH_RD" :props="props">{{ props.row.ND_LH_RD }}</q-td>
					<q-td key="OT_ND_RD_LH" :props="props">{{ props.row.OT_ND_RD_LH }}</q-td>
				</q-tr>
			</template>
			<template v-slot:no-data="">
	          <div class="full-width row flex-center text-red q-gutter-sm">
	            <i class="fas fa-exclamation-triangle"></i>
	            <span>The filter didn't uncover any results!</span>
	          </div>
	        </template>
			<template v-slot:loading>
	            <q-inner-loading showing>
	                <q-spinner-hourglass  size="50px" color="cyan" />
	            </q-inner-loading>
	        </template>
			<template v-slot:top>
			  <q-input dense v-model="filter" placeholder="Search Employee">
				<i class="fas fa-search" slot="append"></i>
			  </q-input>
			  <q-space />
			  <q-btn outline color="primary" label="Download" no-caps @click="exportTable" />
			</template>
		</q-table>
	</div>

	<q-dialog v-model="editDialog" transition-show="rotate" transition-hide="rotate">
      	<q-card style="width: 500px">
        	<q-card-section>
          		<div class="text-h6">Edit TK of {{ newObject.name }}</div>
				<a>{{ newObject.empID }}</a>
        	</q-card-section>

        	<q-card-section class="q-pt-none" >
					<q-form ref="vForm"  class="q-gutter-md" @submit="onSubmit">
					<q-input outlined v-model="newObject.REG_HOUR" label="Regular Hour/Days" />
					<q-input outlined v-model="newObject.UNDER_TIME" label="UNDERTIME"/>
					<q-input outlined v-model="newObject.ABSENT" label="Absent"/>
					<q-input outlined v-model="newObject.RD" label="Restday"/>
					<q-input outlined v-model="newObject.OT_RD" label="Restday-OT"/>
					<q-input outlined v-model="newObject.SH" label="Special Holiday"/>
					<q-input outlined v-model="newObject.OT_SH" label="Special Holiday-OT"/>
					<q-input outlined v-model="newObject.SH_RD" label="Special Holiday-RD"/>
					<q-input outlined v-model="newObject.OT_RD_SH" label="Special Holiday-OT-RD"/>
					<q-input outlined v-model="newObject.LH" label="Legal Holiday"/>
					<q-input outlined v-model="newObject.OT_LH" label="Legal Holiday-OT"/>
					<q-input outlined v-model="newObject.LH_RD" label="Legal Holiday-RD"/>
					<q-input outlined v-model="newObject.OT_RD_LH" label="Legal Holiday-OT-RD"/>
					<q-input outlined v-model="newObject.ND" label="Night Diff"/>
					<q-input outlined v-model="newObject.OT_ND" label="Night Diff-OT"/>
					<q-input outlined v-model="newObject.ND_RD" label="Night Diff-RD"/>
					<q-input outlined v-model="newObject.OT_ND_RD" label="Night Diff-RD-OT"/>
					<q-input outlined v-model="newObject.ND_SH" label="Night Diff-Special Holiday"/>
					<q-input outlined v-model="newObject.OT_ND_SH" label="Night Diff-Special Holiday-OT"/>
					<q-input outlined v-model="newObject.ND_SH_RD" label="Night Diff-Special Holiday-RD"/>
					<q-input outlined v-model="newObject.ND_LH" label="Night Shift-Legal Holiday"/>
					<q-input outlined v-model="newObject.ND_LH_RD" label="Night Shift-Legal Holiday-RD"/>
					<q-input outlined v-model="newObject.OT_ND_RD_LH" label="Night Shift-Legal Holiday-Restday-Overtime"/>

					<q-input outlined autogrow v-model="alterreason"  type="textarea" label="Reason" lazy-rules  :rules="[ val => val && val.length > 0 || 'Please write your reason']"/>

					<q-card-actions align="right">
		          		<q-btn flat label="Close" color="primary" v-close-popup />
						<q-btn flat label="Update" type="submit" color="primary"/>
		        	</q-card-actions>
				</q-form>
        	</q-card-section>


      	</q-card>
    </q-dialog>

	<q-dialog v-model="spinner" persistent>
	  <q-card class="my-card transparent no-shadow">
		<q-card-section>
		  <q-spinner-gears color="cyan" size="5.5em" />
		</q-card-section>
	  </q-card>
	</q-dialog>

</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	data () {
		return {
			filter: '',
	        loading: false,

			spinner:false,
			editDialog:false,
			newObject:{},
			alterreason:'',

	        pagination: {
	            page: 1,
	            rowsPerPage: 15,
	            rowsNumber: 10
	        },

			searchKey:'',
			yearfilter:new Date().getFullYear(),
	        yearOptions: [],
			ratefilter:'ALL',
			rateOptions: ['ALL','MONTHLY','HOURLY'],
			separator:'cell',
			fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),

			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,

			generatedData:[],
			cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
			monthOptions:[
			{id:1,name:'January'},
			{id:2,name:'February'},
			{id:3,name:'March'},
			{id:4,name:'April'},
			{id:5,name:'May'},
			{id:6,name:'June'},
			{id:7,name:'July'},
			{id:8,name:'August'},
			{id:9,name:'September'},
			{id:10,name:'October'},
			{id:11,name:'November'},
			{id:12,name:'December'},],

			data: [],

			columns: [
				{ name:'action', align: 'center', label: 'Action', sortable: true, desc: 'Action' },
				{ name: 'empID', align: 'center', label: 'Employee ID', field: 'empID', sortable: true, desc: 'Employee ID' },
		        { name: 'name', align: 'center', label: 'Name', field: 'name', sortable: true, desc: 'Name' },

		        { name: 'REG_HOUR', align: 'center', label: 'Regular Hour/Days', field: 'REG_HOUR', sortable: true, desc: 'Regular Days' },
		        { name: 'UNDER_TIME', align: 'center', label: 'UNDERTIME', field: 'UNDER_TIME', sortable: true, desc: 'Undertime' },
		       // { name: 'TARDY', align: 'center', label: 'LATE', field: 'TARDY', sortable: true, desc: 'Tardy' },
		        { name: 'ABSENT', align: 'center', label: 'Absent', field: 'ABSENT', sortable: true, desc: 'Absent' },
		        { name: 'OT', align: 'center', label: 'Overtime', field: 'OT', sortable: true, desc: 'Overtime' },
		        { name: 'RD', align: 'center', label: 'Restday', field: 'RD', sortable: true, desc: 'Restday' },
		        { name: 'OT_RD', align: 'center', label: 'Restday-OT', field: 'OT_RD', sortable: true, desc: 'Restday,Overtime' },

		        { name: 'SH', align: 'center', label: 'Special Holiday', field: 'SH', sortable: true, desc: 'Special Holiday' },
		        { name: 'OT_SH', align: 'center', label: 'Special Holiday-OT', field: 'OT_SH', sortable: true, desc: 'Special Holiday, Overtime' },
		        { name: 'SH_RD', align: 'center', label: 'Special Holiday-RD', field: 'SH_RD', sortable: true, desc: 'Special Holiday, Restday' },
		        { name: 'OT_RD_SH', align: 'center', label: 'Special Holiday-OT-RD', field: 'OT_RD_SH', sortable: true, desc: 'Special Holiday,Overtime, Restday'},

		        { name: 'LH', align: 'center', label: 'Legal Holiday', field: 'LH', sortable: true, desc: 'Legal Holiday'},
		        { name: 'OT_LH', align: 'center', label: 'Legal Holiday-OT', field: 'OT_LH', sortable: true, desc: 'Legal Holiday,Overtime'},
		        { name: 'LH_RD', align: 'center', label: 'Legal Holiday-RD', field: 'LH_RD', sortable: true, desc: 'Legal Holiday, Restday'},
		        { name: 'OT_RD_LH', align: 'center', label: 'Legal Holiday-OT-RD', field: 'OT_RD_LH', sortable: true, desc: 'Legal Holiday,Overtime,Restday'},

		        { name: 'ND', align: 'center', label: 'Night Diff', field: 'ND', sortable: true, desc: 'Night Diff'},
		        { name: 'OT_ND', align: 'center', label: 'Night Diff-OT', field: 'OT_ND', sortable: true, desc: 'Night Diff, Overtime'},
		        { name: 'ND_RD', align: 'center', label: 'Night Diff-RD', field: 'ND_RD', sortable: true, desc: 'Night Diff, Restday'},
		        { name: 'OT_ND_RD', align: 'center', label: 'Night Diff-RD-OT', field: 'OT_ND_RD', sortable: true, desc: 'Night Diff, Restday,Overtime'},

		        { name: 'ND_SH', align: 'center', label: 'Night Diff-Special Holiday', field: 'ND_SH', sortable: true, desc: 'Night Diff, Special Holiday'},
		        { name: 'OT_ND_SH', align: 'center', label: 'Night Diff-Special Holiday-OT', field: 'OT_ND_SH', sortable: true, desc: 'Night Shift, Special Holiday,Overtime'},
		        { name: 'ND_SH_RD', align: 'center', label: 'Night Diff-Special Holiday-RD', field: 'ND_SH_RD', sortable: true, desc: 'Night Shift,Special Holiday,Restday' },

		        { name: 'ND_LH', align: 'center', label: 'Night Shift-Legal Holiday', field: 'ND_LH', sortable: true, desc: 'Night Shift, Legal Holiday' },
		        { name: 'ND_LH_RD', align: 'center', label: 'Night Shift-Legal Holiday-RD', field: 'ND_LH_RD', sortable: true, desc: 'Night Shift, Legal Holiday, Restday' },
		        { name: 'OT_ND_RD_LH', align: 'center', label: 'Night Shift-Legal Holiday-Restday-Overtime', field: 'OT_ND_RD_LH', sortable: true, desc: ' Night Shift, Legal Holiday,Restday, Overtime' },


			//leaves
			// { name: 'VL', align: 'center', label: 'VL (days)', field: 'VL', sortable: true ,desc:'Vacation Leave'},
			// { name: 'SL', align: 'center', label: 'SL (days)', field: 'SL', sortable: true ,desc:'Sick Leave'},
			// { name: 'EL', align: 'center', label: 'EL (days)', field: 'EL', sortable: true ,desc:'Emergency Leave'},
			// { name: 'OBT', align: 'center', label: 'OBT (days)', field: 'OBT', sortable: true ,desc:'On Business Trip'},
			// { name: 'ML', align: 'center', label: 'ML (days)', field: 'ML', sortable: true ,desc:'Maternity Leave'},
			// { name: 'PL', align: 'center', label: 'PL (days)', field: 'PL', sortable: true ,desc:'Paternity Leave'},
			// { name: 'MCL', align: 'center', label: 'MCL (days)', field: 'MCL', sortable: true ,desc:'Magna Carta Leave'},
			// { name: 'SPL', align: 'center', label: 'SPL (days)', field: 'SPL', sortable: true ,desc:'Solo Parent Leave'},
			// { name: 'DEDU', align: 'center', label: 'DEDUCT ', field: 'DEDU', sortable: true ,desc:'Deduction'},
			// { name: 'ADJU', align: 'center', label: 'ADJUST', field: 'ADJU', sortable: true ,desc:'Adjustment'},
			// { name: 'TOTAL', align: 'center', label: 'TOTAL', field: 'TOTAL', sortable: true ,desc:'TOTAL'},
			],
		}
	},

	created(){
		this.yearsList()
	},

	mounted(){
        this.onRequest({
            pagination: this.pagination,
            filter: ''
        })
    },

	methods: {

		//start if server side
		onRequest (props) {
	      const { page, rowsPerPage} = props.pagination
	      const filter = props.filter
	      this.loading = true
	      // emulate server
	      setTimeout(() => {
	        this.getRowsNumberCount(filter).then(total => {
	            this.pagination.rowsNumber = total
	        })
	        const fetchCount = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage
	        const startRow = (page - 1) * rowsPerPage
	        this.fetchFromServer(startRow, fetchCount, filter)
	        this.pagination.page = page
	        this.pagination.rowsPerPage = rowsPerPage
	      }, 1500)
	    },

	    fetchFromServer (startRow, count, filter) {
	        axios.get(this.apiUrl + '/timekeeping/generate/summary/list', {
	            headers: { 'Authorization': `Bearer ${this.token}` },
	            params: {
				  		ratefilter: this.ratefilter,
	              year: this.yearfilter,
	              month: this.month,
	              cutoff: this.cutoff,
	              start: startRow,
	              countPerPage: count,
	              filter: filter,
	            }
	        }).then((response) => {
	            this.generatedData = response.data.data
				console.log(response.data)
				this.loading = false
	        }).catch((error) => {
	            console.log(error);
	        })
	   },

	    async getRowsNumberCount (filter) {
	       let response = await axios.get(this.apiUrl + '/timekeeping/generate/summary/total', {
	           headers: { 'Authorization': `Bearer ${this.token}` },
	           params: {
					  ratefilter: this.ratefilter,
	             year: this.yearfilter,
	             month: this.month,
	             cutoff: this.cutoff,
	             filter: filter,
	           }
	       })
	       return response.data
	    },

		///end of server side

		generateDTR : function(){
			this.loading = true
			let formData = new FormData();
			formData.append("year", this.yearfilter);
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/generate/summary`,formData)
			.then((res) => {
				this.onRequest({pagination:this.pagination,filter:''})
			});
		},

		// getTKSummary: function(){
	    //   this.spinner = true;
	    //   let formData = new FormData();
	    //   formData.append("year", this.yearfilter);
	    //   formData.append("month", this.month);
	    //   formData.append("cutoff", this.cutoff);
	    //   axios.create({
	    //       baseURL: this.apiUrl,
	    //       headers: { 'Authorization': `Bearer ${this.token}` }
	    //     }).post(`/timekeeping/generate/summary/list`, formData)
	    //     .then((res) => {
		// 		//console.log(res.data.data)
	    //       this.generatedData = res.data.data
	    //       this.spinner = false
	    //       this.dataFlag = true
	    //     });
	    // },

		yearsList : function(){
	        axios.create({
	            baseURL : this.apiUrl,
	            headers : {'Authorization' : `Bearer ${this.token}`}
	        }).post(`/timekeeping/years/list`)
	        .then((res) => {
	            this.yearOptions = res.data.data
	        });
	    },

		selectEmployee:function(obj){
			this.alterreason ='';
			this.editDialog = true;
			this.newObject = obj;
			//console.log(obj)
		},

		onSubmit:function(){
			this.spinner = true
			var newPostData = JSON.stringify(this.newObject);
			let formData  = new FormData();
			formData.append("id", this.newObject.id);
			formData.append("empID", this.newObject.empID);
			formData.append("reason",this.alterreason);
			formData.append("newObjectData", newPostData);
			axios({
				headers : {'Authorization' : `Bearer ${this.token}`},
				url:this.apiUrl+'/timekeeping/update/summary',
				data: formData,
				method: 'POST',

			}).then(response => {
				this.spinner = false
				this.editDialog = false

			}).catch((error) => {
				console.log(error.response)
			})
		},



		exportTable:function(){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
			'TKSummary.csv',
			content,
			'text/csv'
			)

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
<style lang="sass" scoped>
	// .my-sticky-column-table
	// 	thead tr:nth-child(2) th:nth-child(2)
	// 		background-color: #fff
	// 	td:nth-child(2)
	// 		background-color: #c1f4cd
	// 	th:nth-child(2)
	// 	td:nth-child(2)
	// 		position: sticky
	// 		left: 0
	// 		z-index: 1
</style>

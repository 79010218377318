<template>
	<div class="q-pa-md">
		<div class="row">
			<div  class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
				<q-card class="my-card bg-green-9 text-white hitler-width">
					<q-card-section>
						<div class="text-h5" align="right"><strong>{{ totalActive }}</strong></div>
						<div class="text-subtitle2 hitler-height">
							<i class="fa fa-users fa-6x hitler-icon"></i>
						</div>
					</q-card-section>
					<q-card-actions>
						<q-btn flat>Active Employees</q-btn>
					</q-card-actions>
					<q-tooltip anchor="top middle" content-class="bg-green-9" self="bottom middle" :offset="[10, 10]" >
                    	<strong>Current Active Employees</strong>
                	</q-tooltip>
				</q-card>
			</div>
			<div  class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl hitler-width">
				<q-card class="my-card bg-pink-9 text-white ">
					<q-card-section>
						<div class="text-h5" align="right"><strong>{{ totalNew }}</strong></div>
						<div class="text-subtitle2 hitler-height">
							<i class="fa fa-user-plus fa-6x hitler-icon"></i>
						</div>
					</q-card-section>
					<q-card-actions>
						<q-btn flat align="right">New Employees (month)</q-btn>
					</q-card-actions>
					<q-tooltip anchor="top middle" content-class="bg-pink-9" self="bottom middle" :offset="[10, 10]" >
                    	<strong>Total of New Employees for this month</strong>
                	</q-tooltip>
				</q-card>
			</div>
			<div  class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
				<q-card class="my-card bg-orange-9 text-white">
					<q-card-section>
						<div class="text-h5" align="right"><strong>{{ totalHold }}</strong></div>
						<div class="text-subtitle2 hitler-height">
							<i class="fa fa-user-tag fa-6x hitler-icon"></i>
						</div>
					</q-card-section>
					<q-card-actions>
						<q-btn flat align="right">Hold Employees</q-btn>
					</q-card-actions>
					<q-tooltip anchor="top middle" content-class="bg-orange-9" self="bottom middle" :offset="[10, 10]" >
                    	<strong>Current Total of Hold Employees</strong>
                	</q-tooltip>
				</q-card>
			</div>
			<div  class="col-3 col-lg-3 col-xs-12 col-md-6 q-mb-md q-pr-xl">
				<q-card class="my-card bg-blue-9 text-white ">
					<q-card-section>
						<div class="text-h5" align="right"><strong>{{ totalAccount }}</strong></div>
						<div class="text-subtitle2 hitler-height">
							<i class="fa fa-headset fa-6x hitler-icon"></i>
						</div>
					</q-card-section>
					<q-card-actions>
						<q-btn flat align="right">Active Accounts</q-btn>
					</q-card-actions>
					<q-tooltip anchor="top middle" content-class="bg-blue-9" self="bottom middle" :offset="[10, 10]" >
                    	<strong>Current Total of Active Accounts</strong>
                	</q-tooltip>
				</q-card> 
			</div>
		</div>
		<div class="row">
			<div class="col-8 col-lg-8 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div class="text-h5 q-mt-sm q-mb-xs">Employee Statistic</div>
						<apexchart width="100%" height="300px" type="area" :options="linechartOptions" :series="lineseries"></apexchart>
					 </q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-blue-9" self="bottom middle" :offset="[10, 10]" >
                    <strong>Total Number of Employee Monthly</strong>
                </q-tooltip>
			</div>
			<div class="col-4 col-lg-4 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div class="text-h5 q-mt-sm q-mb-xs">Department Statistic</div>
						<apexchart width="100%" height="313px" type="pie"  :options="pieDeptChartOptions" :series="pieDeptSeries" />
					 </q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-pink-9" self="bottom middle" :offset="[10, 10]" >
                    <strong>Total Number of Employee per Department</strong>
                </q-tooltip>
			</div>
		</div>
		<div class="row">
			<div class="col-8 col-lg-8 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div class="text-h5 q-mt-sm q-mb-xs">Account Statistic</div>
						<apexchart width="100%" height="300px" type="bar" :options="barchartOptions" :series="barseries"></apexchart>
					 </q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-orange-9" self="bottom middle" :offset="[10, 10]" >
                     <strong>Total Number of Employee per Account Monthly</strong>
                </q-tooltip>
			</div>
			<div class="col-4 col-lg-4 col-xs-12  q-mb-md q-pr-xl">
				 <q-card class="my-card">
					<q-card-section>
						<div class="text-h5 q-mt-sm q-mb-xs">Account Statistic</div>
						<apexchart width="100%" height="313px" type="pie"  :options="pieChartOptions" :series="pieSeries" />
					 </q-card-section>
				</q-card>
				<q-tooltip anchor="bottom middle" content-class="bg-green-9" self="bottom middle" :offset="[10, 10]" >
                    <strong>Total Number of Employee per Account</strong>
                </q-tooltip>
			</div>
		</div>
		<q-dialog v-model="spinner" persistent>
            <q-card class="my-card transparent no-shadow">
              <q-card-section>
                <q-spinner-gears color="cyan" size="5.5em" />
              </q-card-section>
            </q-card>
         </q-dialog>
	</div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import axios from 'axios';

export default {
	components: {
		"apexchart" : VueApexCharts,
	},

	data() {
		return {
			spinner:false,
			totalActive:0,
			totalNew:0,
			totalHold:0,
			totalAccount:0,

			lineseries:[],
			linechartOptions:{
					dataLabels: {
						enabled: false,
					},
					stroke:{
						curve: 'smooth',
					},
					xaxis:{
						type: 'datetime',
						categories:[],
					},
					tooltip: {
						x: {
							format: 'MMMM'
						},
					}
			},

		 	pieDeptSeries:[],
	        pieDeptChartOptions:{
	          	labels: [],
	          	responsive: [{
	            breakpoint: 480,
	            options: {
	              	chart: {
	               	 width: 200
	              	},
	              	legend: {
	                	position: 'bottom'
	              	}
	            }
	          	}]
	        },

	        pieSeries:[],
	        pieChartOptions:{
	          	labels: [],
	          	responsive: [{
	            breakpoint: 480,
	            options: {
	              	chart: {
	               	 width: 200
	              	},
	              	legend: {
	                	position: 'bottom'
	              	}
	            }
	          	}]
	        },

        	barseries:[],
			barchartOptions: {
					dataLabels: {
						enabled: false,
					},
					stroke: {
						curve: 'smooth',
					},
					xaxis: {
						type: 'datetime',
						categories: [],
					},
				tooltip: {
					x: {
						format: 'MMMM'
					},
				}
			},
	}
},

created(){
	this.fetchAllData()
},

methods: {

	fetchAllData:function(){

		this.spinner = true
	  	axios.all([
	  		this.getTotalData(),
	  		this.getPieData(),
	  		this.getLineData(),
	  		this.getBarData(),
	  		this.getDeptPieData()
	  		]).then(axios.spread((total,pie,line,bar,dept) => {
	  			//for total data
			    this.totalActive = total.data.data.totalActive
				this.totalNew = total.data.data.totalNew
				this.totalHold = total.data.data.totalHold
				this.totalAccount = total.data.data.totalAccount

				//for pie data
				this.pieSeries = pie.data.data.pieSeries
				pie.data.data.pieLabel.forEach((val) => {
					this.pieChartOptions.labels.push(val)
				})

				//for bar data
				this.barseries = bar.data.data.barseries
				bar.data.data.barcategories.forEach((res) => {
					this.barchartOptions.xaxis.categories.push(res)
				})

				//for line data
				this.lineseries = line.data.data.lineSeries
				line.data.data.lineCat.forEach((res) => {
					this.linechartOptions.xaxis.categories.push(res)
				})

				//for dept pie data
				this.pieDeptSeries = dept.data.data.pieDeptSeries
				dept.data.data.pieDeptLabel.forEach((dept) => {
					this.pieDeptChartOptions.labels.push(dept)
				})

				 setTimeout(() => {
	                this.spinner = false
	            }, 1000);
		
		  }));
	},

	getTotalData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/total');
	},

	getDeptPieData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/pie/dept');
	},

	getPieData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/pie');
	},

	getLineData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/line');
	},

	getBarData:function() {
  		return axios.create({
		        baseURL: this.apiUrl,
		        headers: {
		          'Authorization': `Bearer ${this.token}`
		        }
		      }).get('/dashboard/employee/bar');
	},

	}
}

</script>

<style lang="css" scoped>

.hitler-height{
	height:20px;
}
.hitler-icon{
	margin-top:-40px;
}

</style>
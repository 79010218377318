<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="row justify q-banner--top-padding" v-show="!isLock">
					<q-btn outline color="green" label="Template"  @click="getTemplate()"/>
					<q-btn outline color="red" label="Upload Schedule" @click="showDialog"/>
				</div>
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-select v-model="yearfilter" :options="yearOptions" label="Year"  style="width: 200px"/>
					<q-select v-model="month" :options="monthOptions" label="Month" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="filterByDate"/>
					<q-select v-model="cutoff" :options="cutoffOptions" label="Cut-off" emit-value map-options option-value="id" option-label="name" style="width: 200px" @input="filterByDate"/>
					<q-select v-model="department" :options="departments" option-value="id" option-label="name"  label="ACCOUNT/DEPT" @input="filterByDate" style="width: 200px"/>
					<!--<q-select v-model="department" :options="departments" option-value="id" option-label="name"  label="ACCOUNT/DEPT" @input="filterByDate" style="width: 200px" v-show="myDepartmentID == 19 || myRoleID == 1" />-->
					<q-btn color="primary" label="Filter" @click="filterByDate"/>
					<!--<q-btn color="red" label="Copy Last Cutoff" @click="copyLastSchedule" v-show="showBtnCopy"/>-->
				</div>
			</div>
		</div>

		<div class="q-pa-md">
			<q-table
				class="my-sticky-column-table"
				:dense="$q.screen.lt.md"
				title="SET SCHEDULE"
				:columns="rangeHeader"
				:data="employeeMembers"
				row-key="id"
				:separator="separator"
				:pagination.sync="pagination">
				<template v-slot:header="props">
		        <q-tr :props="props">
		        	<q-th :class="lockColor"><i :class="icon"></i></q-th>
			        <q-th v-for="col in props.cols" :key="col.name" :props="props" > <!-- :colspan="col.span" -->
			            {{ col.label }}
			         </q-th>
		        </q-tr>
		      </template>
				<template v-slot:body="props">
					<q-tr slot="body"  :props="props"  >
						<q-td :class="lockColor"><i :class="icon"></i></q-td>
						<q-td class="text-center"  >{{ props.row.employee_id }}</q-td>
						<q-td class="text-center" @click="selectedRow(props,rangeHeader)"  >{{ props.row.name }}</q-td>
						<q-td v-for="(sched,index) in props.row.schedule" :key="sched.id" @click="sched.fsched == 'OFFSET' ? openOffset(props,index) : ''" class="text-center caps" v-bind:style=" sched.fsched == 'OFFSET' ? 'cursor:pointer;' : 'cursor: context-menu;' ">
							<q-chip :color="sched.fsched == 'RD' || sched.fsched == 'SUSPENDED' || sched.fsched == 'VL' || sched.fsched == 'SL' || sched.fsched == 'OBT'  ? 'red' : sched.fsched == 'OFFSET' ? 'lime' : 'green'" text-color="white" v-show="sched.in !=''">
								<q-avatar :color="sched.isOffSet == 'Y' ? 'green': 'red'" text-color="white" v-show="sched.fsched == 'OFFSET'">
									<i :class="sched.isOffSet == 'Y' ? 'fas fa-check': 'fas fa-times'"></i>
								</q-avatar>
								{{ sched.fsched }}
							</q-chip><br/>
						</q-td>
					</q-tr>
				</template>
				<template v-slot:no-data="">
					<div class="full-width row flex-center text-red q-gutter-sm">
						<i class="fas fa-exclamation-triangle"></i>
						<span>
							No Result Found!
						</span>
					</div>
				</template>
				<!-- <template v-slot:top="props">
					<q-input dense v-model="searchKey" placeholder="Search Employee" class="caps">
						<i class="fas fa-search" slot="append"></i>
					</q-input>
					<q-space />
					<q-btn outline color="primary" label="Download" no-caps @click="exportTable"/>
				</template> -->
			</q-table>
		</div>

		<q-dialog v-model="importfile" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="form" autocomplete="off" @submit="uploadFile">
					<q-card-section>
						<div class="text-h6">Upload Schedule for <span style="color:red">{{ this.department.name }}</span> account?</div>
					</q-card-section>
					<q-card-section>
						<small>(.csv Only)</small><br/>
						<q-file color="teal" filled v-model="files" label="Select your file"  accept=".csv" :rules="[formRules.required]">
					        <template v-slot:prepend>
					          <i class="fa fa-upload"> </i>
					        </template>
					     </q-file>
					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Upload" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" @click="closeModal"/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>

		<q-dialog v-model="isoffset" persistent transition-show="scale" transition-hide="scale">
			<q-card class="q-bottom-sheet--list">
				<q-form ref="form" autocomplete="off" @submit="saveOffsetSchedule">
					<q-card-section>
						<div class="text-h6">OFFSET SCHEDULE</div>
					</q-card-section>
					<q-card-section>
						<q-select outlined v-model="offset" :rules="[formRules.required]" :options="offsetoptions" label="OFFSET" :label-color="validateInput(offset)" />
						<q-input  outlined v-model="dateoffset" mask="date" :rules="['date']"  label="DATE" :label-color="validateInput(dateoffset)">
	  						<template v-slot:append>
	  							<i class="far fa-calendar-alt cursor-pointer" >
	  								<q-popup-proxy ref="dhDateProxy" transition-show="scale" transition-hide="scale">
	  									<q-date v-model="dateoffset" @input="() => $refs.dhDateProxy.hide()" />
	  								</q-popup-proxy>
	  							</i>
	  						</template>
	  					</q-input>
					</q-card-section>
					<q-card-actions align="right">
						<div>
							<q-btn flat label="Submit" type="submit" color="primary"/>
							<q-btn flat label="Close" color="primary" v-close-popup/>
						</div>
					</q-card-actions>
				</q-form>
			</q-card>
		</q-dialog>

		<q-dialog v-model="spinner" persistent >
			<q-card class="my-card transparent no-shadow">
				<q-card-section >
					<q-spinner-gears color="cyan"  size="5.5em"/>
				</q-card-section>
			</q-card>
		</q-dialog>
		<EditSchedule></EditSchedule>
	</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import EditSchedule from './EditSchedule';
import { exportFile } from 'quasar'
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	components: {
		"EditSchedule": EditSchedule,
	},
	data () {
		return {
			myDepartmentID:0,//default for HR
			myRoleID: 1,

			yearfilter:new Date().getFullYear(),
			yearOptions: [],

			importfile:false,
			isoffset:false,
			offsetoptions:['HOLIDAY OFFSET','RESTDAY OFFSET'],
			offset:'',
			dateoffset:'',
			dateapplied:'',

			employeeid:'',
			searchKey:'',
			icon:'fas fa-lock-open',
			isLock:false,
			lockColor:'',
			scheduletable:'',
			members:[],
			employees:[],
			files:[],
			department:{'id':0,'name':'SELECT ACCOUNT'},
			//department:{'id':19,'name':'HUMAN RESOURCES'},
			departments:[],
			separator:'cell',
			fromDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			toDay: new Date().toISOString().slice(0, 10).replace("-", "/").replace("-", "/"),
			spinner:false,
			file:'',
			pagination: {
				rowsPerPage: 30
			},
			month:new Date().getMonth() + 1,
			cutoff: new Date().getDate() > 10 && new Date().getDate() < 26 ? 2 : 1,
			cutoffOptions:[{id:1,name:'First Cut-off'},{id:2,name:'Second Cut-off'}],
			monthOptions:[
			{id:1,name:'January'},
			{id:2,name:'February'},
			{id:3,name:'March'},
			{id:4,name:'April'},
			{id:5,name:'May'},
			{id:6,name:'June'},
			{id:7,name:'July'},
			{id:8,name:'August'},
			{id:9,name:'September'},
			{id:10,name:'October'},
			{id:11,name:'November'},
			{id:12,name:'December'},],
			rangeHeader:[],
			employeeMembers: [],
			initData:[],
			totalDays:0,
			showBtnCopy:false,
			involvemembers:[],
		}
	},

	created(){
		this.myDepartmentID = this.userData.department
		this.myRoleID = this.userData.role_id

		this.eventHub.$on('UpdateSchdule', this.displaySchedule);
		this.getDropdownProperties()
		this.displaySchedule()
		this.yearsList()
	},

	methods: {

		showDialog:function(){
			console.log(this.department.id)
			if(this.department.id === 0){
				this.$q.notify({
					position: 'top',
		          	type: 'negative',
		          	message: 'Please select account for schedule you want to upload!'
		        })
			}else{
				this.importfile = true
			}
		},

		closeModal:function(){
			this.importfile = false
		},

		copyLastSchedule:function(){
			this.involvemembers = []
			for(let i in this.employeeMembers){
				this.involvemembers = [...this.involvemembers,this.employeeMembers[i].employee_id];
			}

			let formData  = new FormData();
			formData.append("year", this.yearfilter);
			formData.append("month", this.month);
			formData.append("cutoff", this.cutoff);
			formData.append("employees", this.involvemembers);

			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/copy',formData)
			.then((res) => {
				this.displaySchedule()
				this.$q.notify({
					message: 'Schedule Copied Successfully!',
					color: 'green',
					position:'center',
					actions: [
				{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }
				]
			})
			}).catch((error) => {
				console.log(error.response)
			})
		},

		displaySchedule: function() {
			this.spinner  = true
			var deptID = (this.department.id == 0) ? this.myDepartmentID : this.department.id;
			//var deptID = (this.userData.is_timekeeper == 'YES') ? this.myDepartmentID : this.department.id;
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get(`/schedule/generate/${this.yearfilter}/${this.month}/${this.cutoff}/${deptID}`)
			.then((res) => {
				this.checkIfCutOffIsLock()
				if(res.data.members.length > 0){
					if(res.data.members[0].schedule[0].in != ''){
						this.showBtnCopy = false;
					}else{
						this.showBtnCopy = true;
					}
				}
				this.rangeHeader = res.data.ranges
				this.employeeMembers = res.data.members
				this.initData = res.data.members
				this.spinner  = false
			})
		},

		filterByDate:function(){
			this.displaySchedule()
		},

		selectedRow:function(emp,rangeHeader){
			//console.log(emp)
			if(this.isLock === true){
				var stat = false
			}else{
				stat = true
			}

			this.eventHub.$emit('EditSchdule', { status: stat, emp: emp.row , header: rangeHeader });
		},

		getDropdownProperties: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).get('/employee/dropdown/properties')
			.then((res) => {
				//console.log(res.data.data.tkDepartments)
				this.departments = res.data.data.tkDepartments
			});
		},

		checkIfCutOffIsLock: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: { 'Authorization': `Bearer ${this.token}` }
			}).get(`/schedule/islock/${this.yearfilter}/${this.month}/${this.cutoff}`)
			.then((res) => {
				if(res.data.scStatus == 'lock'){
					this.isLock = true
					this.icon = 'fas fa-lock'
					this.lockColor = 'text-red'
				}else{
					this.isLock = false
					this.icon = 'fas fa-lock-open'
					this.lockColor = 'text-green'
				}

			});
		},

		getTemplate: function() {
			axios.create({
				baseURL: this.apiUrl,
				headers: {
					'Authorization': `Bearer ${this.token}`
				}})
			.get('/employee/template/download')
			.then((res) => {
				console.log(res.data.data.twosets_template)
				this.downloadTemplate(res.data.data.twosets_template)
				//this.downloadTemplate(res.data.data.monthly_schedule_template)
			})
		},

		uploadFile:function(){
			this.spinner = true
			let formData  = new FormData();
			formData.append("files", this.files);
			formData.append("deptID", this.department.id);
			//formData.append("deptID", this.myDepartmentID);
			axios({
				onUploadProgress: uploadEvent => {
					console.log("Progress: " + Math.round(uploadEvent.loaded / uploadEvent.total * 100) + '%')
				},
				headers : {'Authorization' : `Bearer ${this.token}`},
				//url:this.apiUrl+'/schedule/upload',
				url:this.apiUrl+'/twoset/schedule/upload',
				data: formData,
				method: 'POST'
				}).then(response => {
					this.displaySchedule()
					setTimeout(() => {
						this.spinner = false
						this.importfile = false
						this.files = []
						this.$q.notify({
							message: 'Schedule Uploaded Successfully!',
							color: 'green',
							position:'center',
							actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]

						})
					},500)
				}).catch((error) => {
					console.log(error)
			})
		},

		openOffset:function(obj,index){
			this.dateapplied = obj.cols[index + 2].label
			this.employeeid = obj.row.employee_id
			this.isoffset = true

			axios.get(this.apiUrl + '/schedule/offset/check', {
				headers: { 'Authorization': `Bearer ${this.token}` },
				params: {
				  employeeID: this.employeeid,
				  date: obj.cols[index + 2].label,
				}
			}).then((response) => {
				this.offset = response.data.offset_type
				this.dateoffset = response.data.offset_date
				//console.log(response.data)
			}).catch((error) => {
				console.log(error);
			})
		},

		saveOffsetSchedule:function(){
			let formData  = new FormData();
			formData.append("employeeID", this.employeeid);
			formData.append("type", this.offset);
			formData.append("dateoffset", this.dateoffset);
			formData.append("dateapplied", this.dateapplied);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/schedule/offset',formData).then((res) => {
				console.log(res.data.status)
				if(res.data.status){
					this.$q.notify({
						message: res.data.message,
						color: 'green',
						position:'center',
						actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
					})
					this.isoffset = false
				}else{
					this.$q.notify({
						message: res.data.message,
						color: 'red',
						position:'center',
						actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
					})
				}
				this.displaySchedule()
			}).catch((error) => {
				console.log(error.response)
			})
		},

		validateInput:function(value){
			if (value == null || value == '' || value == 'NULL' || value == 'null'){
				return 'red';
			} else {
				return 'green';
			}
		},


		downloadTemplate:function(url){
			axios({
				url: this.apiUrl+'/'+url,
				method: 'GET',
				responseType: 'arraybuffer',
			}).then((response) => {
				var fileURL = window.URL.createObjectURL(new Blob([response.data]));
				var fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.setAttribute('download', 'Schedule_Template.csv');
				document.body.appendChild(fileLink);

				fileLink.click();
			});
		},

		yearsList : function(){
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/years/list`)
			.then((res) => {
				this.yearOptions = res.data.data
			});
		},

		exportTable:function(){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
			'Schedule.csv',
			content,
			'text/csv'
			)

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>

<template>
	<div class="q-pa-md">
		<div class="q-pa-md">
			<div class="row">
				<div class="q-gutter-md row justify-end q-carousel__navigation-inner row items-start">
					<q-btn color="primary" label="Create" @click="createCutOff"/>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-12 col-xs-12 col-md-6 q-mb-md q-pr-xl">
			<q-table
			:dense="$q.screen.lt.md"
			:grid="$q.screen.xs"
			title="Holiday and OT Rates Table"
			:data="generatedData"
			:columns="columns"
			:pagination="initialPagination"
			row-key="id">
			<template v-slot:body="props">
				<q-tr slot="body"  :props="props" @click="selectedRow(props)" style="cursor: pointer;">
					<q-td key="rates_code" :props="props">{{ props.row.rates_code }}</q-td>
					<q-td key="description" :props="props">{{ props.row.description }}</q-td>
					<q-td key="monthly" :props="props">{{ props.row.monthly }}</q-td>
					<q-td key="hourly" :props="props">{{ props.row.hourly }}</q-td>
				</q-tr>
			</template>
			<template v-slot:no-data="">
				<div class="full-width row flex-center text-red q-gutter-sm">
					<i class="fas fa-exclamation-triangle"></i>
					<span>
						No Result Found!
					</span>
				</div>
			</template>
			<template v-slot:top-right>
				<q-btn outline color="primary" label="Export" no-caps @click="exportTable"/>
			</template>
		</q-table>
	</div>

	<q-dialog v-model="spinner" persistent >
		<q-card class="my-card transparent no-shadow">
			<q-card-section >
				<q-spinner-gears color="cyan"  size="5.5em"/>
			</q-card-section>
		</q-card>
	</q-dialog>

	<q-dialog v-model="addDialog" persistent transition-show="scale">
      	<q-card style="width: 400px">
        	<q-card-section>
          		<div class="text-h6">Create Holiday OT Rates</div>
        	</q-card-section>
        	<q-separator />
        	<q-card-section>
				<div class="q-pa-md" >
					<q-form @submit="onSubmit" class="q-gutter-md">
						<q-input filled v-model="rates_code" label="Rate Code" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
						<q-input filled v-model="description" label="Description" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
						<q-input filled v-model="monthly" label="Monthly" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
						<q-input filled v-model="perhour" label="Per Hour" lazy-rules :rules="[ val => val && val.length > 0 || 'Required']"/>
				  	<div>
						<q-btn label="Submit" type="submit" flat color="primary"/>
						<q-btn label="Close" type="reset" color="primary" flat class="q-ml-sm" v-close-popup/>
				  	</div>
					</q-form>
				</div>
			</q-card-section>
        	<q-separator />
      	</q-card>
    </q-dialog>
</div>
</template>

<script>
import axios from 'axios';
import mixins from '../../mixins';
import { exportFile } from 'quasar'
function wrapCsvValue (val, formatFn) {
	let formatted = formatFn !== void 0 ? formatFn(val) : val

	formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

	formatted = formatted.split('"').join('""')
	return `"${formatted}"`
}
export default {
	data () {
		return {
			tempID:0,
			initialPagination: {rowsPerPage: 30},
			addDialog: false,
			yearfilter:new Date().getFullYear(),
			year:new Date().getFullYear(),

			rates_code:'',
			description:'',
			monthly:'',
			perhour:'',

			yearOptions: [],
			separator:'cell',
			spinner:false,
			generatedData:[],
			data: [],
			columns: [
			{ name: 'rates_code', align: 'center', label: 'Rate Code', field: 'rates_code', sortable: true,  desc:'Status Code' },
			{ name: 'description', align: 'center', label: 'Description', field: 'description', sortable: true,  desc:'Description' },
			{ name: 'monthly', align: 'center', label: 'Monthly', field: 'monthly', sortable: true,  desc:'Monthly' },
			{ name: 'hourly', align: 'center', label: 'Per Hour', field: 'hourly', sortable: true,  desc:'Per Hour' },
			],

		}
	},

	created(){
		this.filterData()
		this.yearsList()
	},

	methods: {
		onSubmit () {
			let formData = new FormData();
			formData.append("tempID", this.tempID);
			formData.append("rates_code", this.rates_code);
			formData.append("description", this.description);
			formData.append("monthly", this.monthly);
			formData.append("hourly", this.perhour);
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post('/backend/holidayotrates/set',formData)
			.then((res) => {
				this.tempID = 0
				this.onReset()
				this.filterData()
				this.$q.notify({
		          color: 'green-9',
		          textColor: 'white',
		          message: 'Submitted'
		        })
				this.addDialog = false
			});

    	},

		onReset() {
			this.rates_code = ''
  		  	this.description = ''
			this.monthly = ''
			this.perhour = ''
			this.tempID = 0
	    },

		createCutOff: function(){
			this.onReset()
			this.addDialog = true
		},

		selectedRow:function(obj){
			this.rates_code = obj.row.rates_code,
			this.description = obj.row.description,
			this.monthly = obj.row.monthly,
			this.perhour = obj.row.hourly,
			this.tempID = obj.row.id
			this.addDialog = true
		},

		filterData : function(){
			this.spinner = true;
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/backend/holidayotrates/table`)
			.then((res) => {
				//console.log(res.data)
				this.generatedData = res.data.data
				this.spinner = false
			});
		},

		yearsList : function(){
			axios.create({
				baseURL : this.apiUrl,
				headers : {'Authorization' : `Bearer ${this.token}`}
			}).post(`/timekeeping/years/list`)
			.then((res) => {
				this.yearOptions = res.data.data
			});
		},

		exportTable:function(){

			const content = [ this.columns.map(col => wrapCsvValue(col.label)) ].concat(
			this.generatedData.map(row => this.columns.map(col => wrapCsvValue(
			typeof col.field === 'function'
			? col.field(row)
			: row[col.field === void 0 ? col.name : col.field],
			col.format
			)).join(','))
			).join('\r\n')

			const status = exportFile(
			'HolidayOTRatesTable.csv',
			content,
			'text/csv'
			)

			if (status !== true) {
				this.$q.notify({
					message: 'Browser denied file download...',
					color: 'negative',
					icon: 'warning'
				})
			}
		},
	}
}
</script>
<style lang="css" scoped>
.center{
	text-align:center
}
</style>
<style lang="sass" scoped>
	.my-sticky-column-table
		thead tr:nth-child(2) th:nth-child(2)
			background-color: #fff
		td:nth-child(2)
			background-color: #c1f4cd
		th:nth-child(2)
		td:nth-child(2)
			position: sticky
			left: 0
			z-index: 1
</style>
